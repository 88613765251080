//react hooks
import { useState, useEffect, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//styles
import Stylesheet from "./style.module.scss";

//utils
import { getJsonData, setQueryParams } from "../../../../../../shared/utils";
import { getDateLocaleString } from "../../../../../../shared/utils/dayjs";
import { getShiftDashBoardParamPayload } from "../../../../../../shared/utils/helper";
import { SyToast } from "../../../../../../shared/utils/sy-toast";

//services
import shiftService from "../../service/shift-service";

//config
import { shedulerButton, status } from "./config";

//redux-slice
import {
  getNavDetails,
  onCatogryClick,
  setGroupList,
  updateMasterData,
  setAvailableList,
} from "../../../../feature/shift-slice";
import {
  delNavItems,
  onShiftGroupClick,
  onShiftGroupDelete,
} from "../../../../feature/shift-slice/nav-slice";
import {
  getAvailableList,
  getGroupList,
  getMemberList,
  getShiftAccessList,
  setMemberList,
  setShiftAccessList,
  setShiftList,
} from "../../../../feature/shift-slice/scheduler-slice";

//helper
import { getParamValue } from "./helper";

//components
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import Popup from "../../../../../../shared/components/sy-model";
import SyIcons from "../../../../../../shared/components/sy-icons/sy-icons";
import SyconfirmPopup from "../../../../../../shared/components/sy-confirmPopup";
import Overview from "./components/overview";
import AvailableList from "../../components/available-list";
import SySheduler from "../../../../../../shared/components/sy-sheduler";
import ShiftPopup from "../../popups/new-shift-popup";
import ShiftGroupManage from "../../popups/create-shift-group-popup/create-shift-group";
import ShiftCategoryManage from "../../popups/create-shift-category";
import Dashboardnav from "./components/dashboard-nav/dashboard-nav";
import { getUserName, getuserDetailReducer } from "../../../../feature/common-slice";
import MemberList from "../../components/member-list";
import { SyLoadingIcon } from "../../../../../../shared/components";
import Dropdown from "../../../../../../shared/components/forms/dropdown";

const DashboardScreen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  //--states--//
  const [weekSheduler, setWeekSheduler] = useState<any>([]);
  const [openShift, setOpenShift] = useState(false);
  const [openShiftEdit, setOpenShiftEdit] = useState(false);
  const [editShiftValues, setEditShiftValue] = useState("");
  const [defaultShiftDate, setDefaultShiftDate] = useState<any>([]);
  const [openShiftCategoryPopup, setOpenCategoryPopup] = useState(false);
  const [categoryConfirmPopup, setCategoryConfirmPopup] = useState(false);
  const [groupConfirmPopup, setGroupConfirmPopup] = useState(false);
  const [openShiftGroupPopup, setOpenShiftGroupPopup] = useState(false);
  const [categoryId, setCategoryId] = useState<any>();
  const [groupId, setGroupId] = useState<any>(null);
  const [shedulerChange, setShedulerChange] = useState(1);
  const [userData, setUserData] = useState(true);
  const [assigned, setAssigned] = useState<any>();
  const [width, setWidth] = useState(window.innerWidth);
  const [accEmp, setAccEmp] = useState<any>();
  //-selectors--//
  const navDetails = useSelector(getNavDetails);
  const groupList = useSelector(getGroupList);
  const mem = useSelector(getMemberList);
  const available: any = useSelector(getAvailableList);
  // const shiftList:any = useSelector(getShiftList)

  // const handleShiftLabel = (data: any) => data?.name;
  const handleShiftLabel = (data: any) => {
    const checkName = data?.name;
    return checkName;
  }


  const handleShiftChange = (data: any) => {
    setAccEmp(data);
    // ShiftAccessMembers(payload);
  }

  const userDetail = useSelector(getuserDetailReducer);


  const onNavButton = () => {
    setOpenCategoryPopup(true);
  };

  const onGroupPopupClick = () => {
    setOpenShiftGroupPopup(true);
  };

  const onCloseShiftCategoryPopup = () => {
    setOpenCategoryPopup(false);
    setCategoryId(null);
    getCategoryMemberList(weekSheduler);
  };

  const onShiftGroupPopupClose = () => {
    setOpenShiftGroupPopup(false);
    getCategoryMemberList(weekSheduler);
    getAvailableLists(weekSheduler);
    getShiftAssignedData(weekSheduler);
  };

  const onExportButton = () => {
    getExport(weekSheduler);
  };

  const onPdfButton = () => {
    alert("pdfbutton");
  };

  useEffect(() => {
    checkIsUserDataAvailable();
    getCategoryList();
    const params = getParamValue(searchParams);
    params?.cgy && getShiftList(params.cgy);
    if (params.sg) {
      getShiftDetails(params.sg);
    } else if (params.cgy) {
      getShiftCategory(params.cgy);
    } else {
      void null;
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  //--mutation--//
  const { mutate: getShiftDetails, isLoading: getGroupLoading } = useMutation({
    mutationFn: shiftService.getShiftGroupData,
    onSuccess(data) {
      dispatch(onShiftGroupClick(data.data));
    },
  });

  //assigned
  const { mutate: getShiftAssigned, isLoading: getAssignedLoading } = useMutation({
    mutationFn: shiftService.getShiftAssignedData,
    onSuccess(data) {
      setAssigned(data.data)
    }
  })

  //available
  const { mutate: availableList, isLoading: getAvailableLoading } = useMutation({
    mutationFn: shiftService.getAvailableList,
    onSuccess(data) {
      dispatch(setAvailableList(data.data));
    },
  });

  //memberList
  const { mutate: shiftCategoryMemberList, isLoading: getMemberLoading } = useMutation({
    mutationFn: shiftService.getCategoryMemberList,
    onSuccess(data: any) {
      dispatch(setMemberList(data.data))
    },
  });

  //exportlist
  const { mutate: getExportList } = useMutation({
    mutationFn: shiftService.getExportList,
    onSuccess(data: any) {
      // const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // const url = window.URL.createObjectURL(blob);

      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'shift.xlsx');
      // document.body.appendChild(link);

      // link.click();

      // // Cleanup
      // document.body.removeChild(link);
      // window.URL.revokeObjectURL(url);
      // const a = document.createElement('a');
      // a.setAttribute('download', this.translate.instant('client_data') + '.xlsx');
      // a.setAttribute('href', res.file);
      // a.setAttribute('id', 'excel-dowonload');
      // const app: any = document.getElementById('app-body');
      // app.appendChild(a);
      // document.getElementById('excel-dowonload').click();
      // document.getElementById('excel-dowonload').remove();

      //js
      // const url = window.URL.createObjectURL(new Blob([data.file]));
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", "shift.xlsx");
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      window.location.href = data.data.file;

    },
  });

  // const { mutate: getExportList } = useMutation({
  //   mutationFn: shiftService.getExportList,
  //   onSuccess(data: any) {
  //     console.log(data , 'dataofexpor');

  //     const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  //     // Create a download link and click it
  //     const url = window.URL.createObjectURL(data);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', 'shift.xlsx');
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   },
  // });

  // const getExportList = (val:any) =>{
  //   const setPayload = (val:any)=>{
  //     const payload = {
  //       date: val?.date,
  //       type: shedulerChange === 1 ? "Week" : "Month",
  //     };
  //     getExportList(payload);
  //   }

  // }

  const getShiftAssignedData = (val: any) => {
    const setPayload = (val: any) => {
      const payload = {
        category_id: navDetails.clickedData?.selected?.sg_id
          ? null
          : `${navDetails.clickedData?.cgy_id}`,
        group_id: navDetails.clickedData?.selected?.sg_id
          ? `${navDetails.clickedData?.selected?.sg_id}`
          : null,
        date: val?.date,
        type: shedulerChange === 1 ? "Week" : "Month",
      };
      getShiftAssigned(payload);
    };

    if (val.length <= 7) {
      const defaultShiftDate = val.find((data: any) => {
        return data.day == "Mon";
      });

      setPayload(defaultShiftDate);
    } else {
      const defaultShiftDate = val.find((data: any) => {
        return data.day == "1";
      });
      setPayload(defaultShiftDate);
    }
  };
  const disable = navDetails.clickedData.selected?.sg_id ? true : false;

  //memberList
  const getCategoryMemberList = (val: any) => {
    const setPayload = (val: any) => {
      const payload = {
        // category_id: navDetails.clickedData?.cgy_id
        //   ? navDetails.clickedData?.cgy_id
        //   : `${navDetails.clickedData?.cgy_id}`,
        category_id: navDetails?.clickedData?.cgy_id,
        // group_id: navDetails.clickedData?.selected?.sg_id
        //   ? `${navDetails.clickedData?.selected?.sg_id}`
        //   : null,
        // categoryId:navDetails.clickedData?.cgy_id,
        date: val?.date,
        type: shedulerChange === 1 ? "Week" : "Month",
      };
      shiftCategoryMemberList(payload);
    };

    if (val.length <= 7) {
      const defaultShiftDate = val.find((data: any) => {
        return data.day == "Mon";
      });

      setPayload(defaultShiftDate);
    } else {
      const defaultShiftDate = val.find((data: any) => {
        return data.day == "1";
      });
      setPayload(defaultShiftDate);
    }
  };

  //assigned
  const getAvailableLists = (val: any) => {
    const setPayload = (val: any) => {
      const payload = {
        category_id: navDetails.clickedData?.selected?.sg_id
          ? null
          : `${navDetails.clickedData?.cgy_id}`,
        group_id: navDetails.clickedData?.selected?.sg_id
          ? `${navDetails.clickedData?.selected?.sg_id}`
          : null,
        date: val?.date,
        type: shedulerChange === 1 ? "Week" : "Month",
      };
      availableList(payload);
    };

    if (val.length <= 7) {
      const defaultShiftDate = val.find((data: any) => {
        return data.day == "Mon";
      });

      setPayload(defaultShiftDate);
    } else {
      const defaultShiftDate = val.find((data: any) => {
        return data.day == "1";
      });
      setPayload(defaultShiftDate);
    }
  };

  //export
  const getExport = (val: any) => {
    const setPayload = (val: any) => {
      const payload = {
        category_id: navDetails.clickedData?.selected?.sg_id
          ? null
          : `${navDetails.clickedData?.cgy_id}`,
        group_id: navDetails.clickedData?.selected?.sg_id
          ? `${navDetails.clickedData?.selected?.sg_id}`
          : null,
        date: val?.date,
        type: shedulerChange === 1 ? "Week" : "Month",
      };
      getExportList(payload);
    };

    if (val.length <= 7) {
      const defaultShiftDate = val.find((data: any) => {
        return data.day == "Mon";
      });

      setPayload(defaultShiftDate);
    } else {
      const defaultShiftDate = val.find((data: any) => {
        return data.day == "1";
      });
      setPayload(defaultShiftDate);
    }
  };

  const { mutate: getShiftCategory, isLoading: isLoading } = useMutation({
    mutationFn: shiftService.getShiftEditCategory,
    onSuccess(data: any) {
      dispatch(onCatogryClick(data.data));
    },
  });

  const { mutate: getCategoryList, isLoading: cgyLoading } = useMutation({
    mutationFn: shiftService.getCategoryGroup,
    onSuccess(data: any) {
      parentOptions(data);
    },
  });

  const { mutate: categoryDelete, isLoading: shiftDeleteLoading } = useMutation(
    {
      mutationFn: shiftService.deleteShiftCategory,
      onSuccess(data: any, variables: any) {
        dispatch(delNavItems(variables));
        setCategoryId(null);
        SyToast(data.data.message, "top-right", "short", "success");
        // setQueryParams(getShiftDashBoardParamPayload(`cgy=${ data?.data?.id}`));
        // setQueryParams(getShiftDashBoardParamPayload(`cgy=${ data?.data?.id}`));
      },
    }
  );

  const { mutate: groupDelete, isLoading: groupDeleteLoading } = useMutation({
    mutationFn: shiftService.deleteShiftGroup,
    onSuccess(data, variables) {
      dispatch(onShiftGroupDelete(variables));
      const sg = [...groupList];
      dispatch(
        setGroupList(sg.filter((data: any) => data.sg_id !== variables))
      );
      setGroupId(null);
      setQueryParams(getShiftDashBoardParamPayload(`cgy=${navDetails.clickedData.cgy_id}`));
      SyToast(data.data.message, "top-right", "short", "success");
    },
  });

  const { mutate: getShiftList, isLoading: sgLoading } = useMutation({
    mutationFn: shiftService.getShiftGroup,
    onSuccess(data: any) {
      dispatch(setGroupList(data.data));
    },
  });

  const { data: wildcardData, mutate: getMembers } = useMutation({
    mutationFn: shiftService.getwildCard,
  });

  const parentOptions = (data: any) => {
    const optionsArray: any = [];
    data?.data?.data.forEach((data: any) => {
      optionsArray.push(data);
    });
    dispatch(updateMasterData(optionsArray));
  };

  const onEditCategoryGroupPopup = () => {
    if (navDetails.clickedData.length !== 0) {
      if (navDetails.clickedData.selected?.sg_id) {
        setGroupId(navDetails.clickedData.selected.sg_id);
        setOpenShiftGroupPopup(true);
      } else {
        setCategoryId(navDetails.clickedData?.cgy_id);
        setOpenCategoryPopup(true);
      }
    }
  };

  const onCloseShiftGroupPopup = () => {
    setOpenShiftGroupPopup(false);
    setGroupId(null);
  };

  const setParam = (data: any, from: any) => {
    if (from === "categoryClick") {
      setQueryParams(getShiftDashBoardParamPayload(`cgy=${data?.cgy_id}`));
    } else if (from === "shiftgroup") {
      setQueryParams(
        getShiftDashBoardParamPayload(
          `cgy=${data.sg_category}/sg=${data?.sg_id}`
        )
      );
    }
  };

  const handleShiftDashboardNavClick = (data: any) => {
    dispatch(onCatogryClick(data));
    getShiftList(data?.cgy_id);
    dispatch(setGroupList([]));
    setParam(data, "categoryClick");
    window.location.reload();
  };

  const handleShiftgroupClick = (data: any) => {
    setParam(data, "shiftgroup");
    dispatch(onShiftGroupClick(data));
    window.location.reload();
  };

  const onDeleteCategoryGroupPopup = () => {
    if (navDetails.clickedData.length !== 0) {
      if (navDetails.clickedData.selected?.sg_id) {
        setGroupId(navDetails.clickedData.selected.sg_id);
        setGroupConfirmPopup(true);
      } else {
        setCategoryId(navDetails.clickedData.cgy_id);
        setCategoryConfirmPopup(true);
      }
    }
  };

  const onConfirmCategoryDelete = () => {
    categoryDelete(categoryId);

    dispatch(setGroupList([]));
  };

  const onConfirmGroupDelete = () => {
    groupDelete(groupId);
  };

  const onSaveShiftGroup = (variables: any) => {
    const cgy_id = `${variables.payload.default.sg_category}`;
    if (variables.id) {
      const sgList = [...groupList];
      const index = sgList.findIndex((sg: any) => sg.sg_id === variables.id);
      let sg = { ...sgList[index] };
      sg.sg_name = variables.payload.default.sg_name;
      sgList[index] = sg;

      dispatch(setGroupList(sgList));
    } else {
      cgy_id == navDetails?.clickedData.cgy_id &&
        getShiftList(variables.payload.default.sg_category);
    }

    if (`${navDetails?.clickedData.cgy_id}` !== cgy_id) {
      const sgList = [...groupList].filter(
        (data: any) => navDetails.clickedData.selected.sg_id !== data.sg_id
      );

      dispatch(setGroupList(sgList));

      if (navDetails.clickedData.selected.sg_id === variables.id) {
        dispatch(onShiftGroupDelete(variables.id));
      }
    }
  };

  const onFetchCategoryList = () => getCategoryList();
  const shift_admin: any = userData;

  const checkIsUserDataAvailable = async () => {
    // const userDetail = await getJsonData("user_details");

    if (userDetail?.type == "1") {
      setUserData(true);
    } else {
      setUserData(false);
    }
  };

  // const shift_admin: any = userData; 

  const onShedulerAddShow = (val: any) => {
    if (val !== "new") {
      const currentDate = getDateLocaleString(new Date(), "year");

      if (currentDate <= val.date && disable) {
        setDefaultShiftDate(val.date);
        setOpenShift(true);
      } else {
        SyToast("disable_add_shift", "top-right", "short", "error");
      }
    } else {
      setDefaultShiftDate([]);
      setOpenShift(true);
    }
    // getShiftList(navDetails.clickedData?.cgy_id);
  };

  const onShedulerEditShow = (val: any) => {
    // getShiftList(navDetails.clickedData?.cgy_id);
    setEditShiftValue(val);
    setOpenShiftEdit(true);
  };

  const onShiftPopupClose = () => {
    weekList(weekSheduler);
    getAvailableLists(weekSheduler);
    getCategoryMemberList(weekSheduler)
    setOpenShift(false);
    setOpenShiftEdit(false);
    getShiftAssignedData(weekSheduler);
  };

  const { mutate: ShiftList } = useMutation({
    mutationFn: shiftService.getShiftList,
    onSuccess(data: any, variables: any) {
      dispatch(setShiftList(data.data));
    },
  });

  const { mutate: ShiftAccessMembers } = useMutation({
    mutationFn: shiftService.getAccessMembers,
    onSuccess(data: any) {
      dispatch(setShiftAccessList(data.data));
    },
  });


  // const weekList = (val: any) => {
  //   setWeekSheduler(val);

  //   const setPayload = (value: any) => {
  //     if (shift_admin) {
  //       const payload = {
  //         category_id: navDetails.clickedData?.selected?.sg_id
  //           ? null
  //           : `${navDetails.clickedData?.cgy_id}`,
  //         group_id: navDetails.clickedData?.selected?.sg_id
  //           ? `${navDetails.clickedData?.selected?.sg_id}`
  //           : null,
  //         date: value.date,
  //         type: value.shedulerChange == "1" ? "Week" : "Month",
  //         // user_id : accEmp?.id,
  //         // user_typeId : accEmp?.user_id
  //         user_id : accEmp?.id,
  //         user_typeId : accEmp?.type_id 
  //       };
  //       ShiftList(payload);

  //       // ShiftAccessMembers(payload);

  //     } else if (!shift_admin) {
  //       const payload = {
  //         date: value.date,
  //         type: value.shedulerChange == "1" ? "Week" : "Month",
  //       };
  //       ShiftAccessMembers(payload);
  //       // getExportList(payload);
  //     }
  //   };

  //   if (val.length <= 7) {
  //     const defaultShiftDate = val.find((data: any) => {
  //       return data.day == "Mon";
  //     });

  //     setPayload(defaultShiftDate);
  //   } else {
  //     const defaultShiftDate = val.find((data: any) => {
  //       return data.day == "1";
  //     });
  //     setPayload(defaultShiftDate);
  //   }
  // };

  const onMyShiftClick = () => {
    setUserData(!userData);

    let payloadVal: any;

    if (userData) {
      if (weekSheduler.length <= 7) {
        const defaultShiftDate = weekSheduler.find((data: any) => {
          return data.day == "Mon";
        });

        payloadVal = defaultShiftDate;
      } else {
        const defaultShiftDate = weekSheduler.find((data: any) => {
          return data.day == "1";
        });
        payloadVal = defaultShiftDate;
      }

      const payload = {
        date: payloadVal.date,
        type: payloadVal.shedulerChange == "1" ? "Week" : "Month",
        groupId: navDetails.clickedData?.selected?.sg_id,
        user_id: userDetail.user_id,
        user_type: userDetail.master_type
      };
      ShiftAccessMembers(payload);
    }
  }

  useEffect(() => {
    if (weekSheduler.length > 0) {
      weekList(weekSheduler);
    }
  }, [navDetails.clickedData?.cgy_id, navDetails.clickedData?.selected?.sg_id, accEmp]);

  // useEffect(() => {
  //   if (weekSheduler.length > 0) {
  //     weekList(weekSheduler);
  //   }
  // }, [navDetails.clickedData?.cgy_id, navDetails.clickedData?.selected?.sg_id , accEmp]);

  useEffect(() => {
    if (navDetails.clickedData?.selected?.sg_id && weekSheduler.length > 0) {
      weekSheduler && getAvailableLists(weekSheduler);
    }
  }, [navDetails.clickedData?.selected?.sg_id, weekSheduler]);

  useEffect(() => {
    if (navDetails?.clickedData?.cgy_id && weekSheduler.length > 0) {
      weekSheduler && getCategoryMemberList(weekSheduler);
    }
  }, [navDetails?.clickedData?.cgy_id, weekSheduler]);

  useEffect(() => {
    if (navDetails?.clickedData?.cgy_id && weekSheduler.length > 0) {
      weekSheduler && getShiftAssignedData(weekSheduler);
    }
  }, [navDetails?.clickedData?.cgy_id, weekSheduler]);

  const weekList = (val: any) => {
    setWeekSheduler(val);

    const setPayload = (value: any) => {
      if (shift_admin) {
        const payload = {
          category_id: navDetails.clickedData?.selected?.sg_id
            ? null
            : `${navDetails.clickedData?.cgy_id}`,
          group_id: navDetails.clickedData?.selected?.sg_id
            ? `${navDetails.clickedData?.selected?.sg_id}`
            : null,
          date: value.date,
          type: value.shedulerChange == "1" ? "Week" : "Month",
          // user_id : accEmp?.id,
          // user_typeId : accEmp?.user_id
          user_id: accEmp?.id,
          user_type: accEmp?.type_id
        };

        if (accEmp?.id) {
          ShiftAccessMembers(payload);
        } else {
          ShiftList(payload);
        }
        // ShiftList(payload);

        // ShiftAccessMembers(payload);

      } else if (!shift_admin) {
        const payload = {
          date: value.date,
          type: value.shedulerChange == "1" ? "Week" : "Month",
          groupId: navDetails.clickedData?.selected?.sg_id,
          user_id: userDetail.user_id,
          user_type: userDetail.master_type
        };
        ShiftAccessMembers(payload);
        // getExportList(payload);
      }
    };

    if (val.length <= 7) {
      const defaultShiftDate = val.find((data: any) => {
        return data.day == "Mon";
      });

      setPayload(defaultShiftDate);
    } else {
      const defaultShiftDate = val.find((data: any) => {
        return data.day == "1";
      });
      setPayload(defaultShiftDate);
    }
  };

  // const prevNavDetailsRef = useRef();
  // const prevWeekShedulerRef = useRef();

  // useEffect(() => {
  //   const prevNavDetails:any = prevNavDetailsRef.current;
  //   const prevWeekSheduler:any = prevWeekShedulerRef.current;

  //   if (
  //     (prevNavDetails?.clickedData?.cgy_id !== navDetails?.clickedData?.cgy_id) ||
  //     (prevWeekSheduler !== weekSheduler)
  //   ) {
  //     weekSheduler && getCategoryMemberList(weekSheduler);
  //     console.log(weekSheduler , 'weeksheduler');
  //     console.log(navDetails?.clickedData?.cgy_id , 'cgy_id');
  //   }

  //   prevNavDetailsRef.current = navDetails;
  //   prevWeekShedulerRef.current = weekSheduler;
  // }, [navDetails, weekSheduler]);

  const onChangeType = (val: any) => {
    setShedulerChange(val);
  };

  const [menuVisible, setMenuVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setMenuVisible(!menuVisible);
  };

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <>
      {openShiftGroupPopup && (
        <Popup
          open={openShiftGroupPopup}
          close={onShiftGroupPopupClose}
          onClose={onCloseShiftGroupPopup}
          model_type={"responsive"}
          style={{ width: "1000px" }}
          title={groupId ? t("edit_shift_planner") : t("shift_planner")}
        >
          <ShiftGroupManage
            saveVariables={onSaveShiftGroup}
            groupOptions={navDetails?.master}
            clientOptions={wildcardData}
            closePopup={onCloseShiftGroupPopup}
            shiftId={groupId}
          />
        </Popup>
      )}
      {openShiftCategoryPopup && (
        <Popup
          open={openShiftCategoryPopup}
          close={onCloseShiftCategoryPopup}
          onClose={onCloseShiftCategoryPopup}
          model_type={"responsive"}
          title={categoryId ? t("edit_category") : t("create_category")}
        >
          <ShiftCategoryManage
            statusOptions={status}
            close={onCloseShiftCategoryPopup}
            fetchList={onFetchCategoryList}
            editData={categoryId}
          />
        </Popup>
      )}
      <SyconfirmPopup
        open={categoryConfirmPopup}
        close={setCategoryConfirmPopup}
        onConfirmClick={onConfirmCategoryDelete}
      />
      <SyconfirmPopup
        close={setGroupConfirmPopup}
        open={groupConfirmPopup}
        onConfirmClick={onConfirmGroupDelete}
      />

      {openShift && (
        <Popup
          open={openShift}
          close={setOpenShift}
          model_type={"responsive"}
          style={{ width: "1000px" }}
          title={`create_shift`}
        >
          <ShiftPopup
            close={onShiftPopupClose}
            defaultShiftDate={defaultShiftDate}
            shedulerChange={shedulerChange}
          />
        </Popup>
      )}
      {openShiftEdit && (
        <Popup
          open={openShiftEdit}
          close={onShiftPopupClose}
          model_type={"responsive"}
          maxWidth={"lg"}
          title={`edit_shift`}
        >
          <ShiftPopup
            close={onShiftPopupClose}
            editShiftValues={editShiftValues}
            shift_admin={shift_admin}
            shedulerChange={shedulerChange}
          />
        </Popup>
      )}
      <div className={`${Stylesheet.container_fluid} container-fluid`}>
        <div className={`${Stylesheet.container} mb-2`}>
          <div className="d-flex align-items-center justify-content-between pt-3 pb-2 ps-2">
            <div className="d-flex align-items-center">
              {/* <SyIcons name="chevron_left" className={"pointer"} />
              <span className={`pointer`}>
                {shift_admin ? "Shiftplanner" : "Steffens shifts"}
              </span> */}
            </div>
            <div className=" text-right d-flex justify-content-end me-4 gap-3">
              {(userDetail?.type == "1" && navDetails.clickedData?.selected?.sg_id) && (
                <Sybutton
                  type={"button"}
                  className={`primarybutton ${Stylesheet.plan}`}
                  size={"sm"}
                  value={"plan"}
                  label={userData ? t("my_shifts") : t("shift_planner")}
                  onBtnClick={onMyShiftClick}
                />
              )}
              {/* <Sybutton
                type={"button"}
                className={`primarybutton ${Stylesheet.plan}`}
                size={"sm"}
                value={"plan"}
                label={t("export_plan")}
                onBtnClick={onExportButton}
              /> */}
              {/* <Sybutton
                type={"button"}
                className={`primarybutton ${Stylesheet.plan}`}
                size={"sm"}
                value={"plan"}
                label={t("print_plan")}
                onBtnClick={onPdfButton}
              /> */}
            </div>
          </div>
          { }
          {shift_admin && (
            <>
              <div
                className={` ms-2 me-2 p-2 ${navDetails.clickedData.length !== 0 ? "pb-1" : ""
                  } `}
              >
                <Dashboardnav
                  onClickBtn={handleShiftDashboardNavClick}
                  onShiftgroupClick={handleShiftgroupClick}
                  data={navDetails?.master}
                  onGroupPopupClick={onGroupPopupClick}
                  onCategoryPopupClick={onNavButton}
                  cgyLoading={cgyLoading}
                  sgLoading={sgLoading}
                  assigned={assigned}
                />
              </div>

              {navDetails.clickedData.length !== 0 && (
                <div className="d-flex justify-content-between m-2">
                  <div className={`me-2 ms-2  col-lg-3  col-lg-3 col-md-4 col-sm-5 col-xs-8 ${Stylesheet.dropdownDash}`}>
                    {/* <input/> */}
                    <Dropdown
                      options={mem}
                      placeholder={"employee"}
                      handleChange={handleShiftChange}
                      getoptlabel={handleShiftLabel}
                      clearIcon={false}
                    />
                  </div>
                  <div className="d-flex justify-content-end px-1 pb-1 pe-2">
                    {assigned?.shiftNotAssignedCount > 0 &&
                      <div className="d-flex me-2 ms-2">
                        <Sybutton
                          type={"button"}
                          className={`${Stylesheet.flagbutton} d-flex align-items-center text-white`}
                          size={"sm"}
                          icontype="outlined_flag"
                          value={width > 423 ? `${assigned?.shiftNotAssignedCount} ${t("shifts_not_assigned")}` : `${assigned?.shiftNotAssignedCount}`}
                          onBtnClick={void null}
                          iconclass={Stylesheet.flagIcon}
                        />
                      </div>
                    }
                    <Sybutton
                      type={"button"}
                      className={`primarybutton me-2 p-1 ${Stylesheet.plan}`}
                      size={"sm"}
                      value={"plan"}
                      label={t("export_plan")}
                      onBtnClick={onExportButton}
                    />
                    {/* <Sybutton
                      type={"button"}
                      className={"iconButton text-white me-2"}
                      size={"sm"}
                      icontype="edit"
                      onBtnClick={onEditCategoryGroupPopup}
                    />
                    <Sybutton
                      type={"submit"}
                      className={"iconButton text-white"}
                      size={"sm"}
                      icontype="delete"
                      onBtnClick={onDeleteCategoryGroupPopup}
                      Loadings={shiftDeleteLoading || groupDeleteLoading}
                    /> */}
                    <Sybutton
                      type={"button"}
                      className={""}
                      size={"sm"}
                      icontype="more_vert"
                      onBtnClick={toggleDropdown}
                      Loadings={shiftDeleteLoading || groupDeleteLoading}
                    />
                    <div className={`${Stylesheet.header}`}>
                      <div className={`${Stylesheet.dropdown}`} ref={dropdownRef}>
                        {menuVisible && (
                          <div id="myDropdown" className={`${Stylesheet.dropdown_content} ${Stylesheet.show} text-center`}>
                            <a className="cursor-pointer d-inline-flex align-items-center" onClick={onEditCategoryGroupPopup}>
                              <span className="material-symbols-outlined me-2 align-items-center">edit</span>
                              Edit
                            </a>
                            <a className="cursor-pointer d-inline-flex align-items-center" onClick={onDeleteCategoryGroupPopup} style={{ display: 'inline-flex', alignItems: 'center' }}>
                              <span className="material-symbols-outlined me-2 align-items-center">delete</span>
                              Delete
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div className={`d-flex g-0 px-3 pb-2  flex-wrap`}>
            <div className={`col-lg-3  col-md-4 col-sm-5 col-12 pe-2 mb-2`}>
              {/* main */}
              {/* {navDetails.clickedData.length === 0 && (
                <div className={`${Stylesheet.info_content}  `}>
                  {"no_data_found"}
                </div>
              )}   */}


              {(navDetails.clickedData.length == 0 || navDetails.master.length == 0) && userData && (
                <div className={`${Stylesheet.info_content}`}>
                  {"no_data_found"}
                </div>
              )}
              {/* {navDetails && navDetails.clickedData && navDetails.clickedData.length == 0 && (
                <div className={`${Stylesheet.info_content}  `}>
                  {"no_data_found"}
                </div>
              )} */}
              {/* {Array.isArray(navDetails.clickedData) && navDetails.clickedData.length === 0 && (
                <div className={`${Stylesheet.info_content}`}>
                  {"no_data_found"}
                </div>
              )} */}
              {/* {!shift_admin && <Preference />} */}

              {/* {shift_admin ? (
                navDetails.clickedData?.selected?.sg_id ? (
                  <Overview
                    sg_id={navDetails?.clickedData?.selected?.sg_id}
                    type={shedulerChange}
                  />
                ) : (
                  navDetails.clickedData.cgy_id && (
                    <>
                      <Overview type={shedulerChange} />
                      <div className="pt-2">
                        <MemberList memberLoading={getMemberLoading} />
                      </div>
                    </>
                  )
                )
              ) : null} */}

              {shift_admin ? (
                navDetails.clickedData?.cgy_id && (
                  <>
                    <div>
                      <MemberList memberLoading={getMemberLoading} />
                    </div>
                  </>
                )
              ) : null}

              {shift_admin && navDetails.clickedData?.selected?.sg_id && available?.length > 0 && (
                <div className="pt-2">
                  <AvailableList availableLoading={getAvailableLoading} />
                </div>
              )}
            </div>
            
            {sgLoading ? (
              <div className={`col-md-8 col-sm-7 col-lg-9 col-12 ${Stylesheet.loaderCard}`}>
                <SyLoadingIcon size="sm" />
              </div>
            ) :
              <div
                className={` ${shift_admin ? "col-md-8 col-sm-7 col-lg-9 col-12" : "col-lg-12"
                  } pb-3 border-0 col-12  `}
              >
                {navDetails.clickedData.length === 0 && userData ? (
                  <div className={Stylesheet.info_content}>{"no_data_found"}</div>
                ) : (
                  <div className="">
                    <SySheduler
                      headerButton={shedulerButton}
                      shiftAdd={onShedulerAddShow}
                      shiftEdit={onShedulerEditShow}
                      groupId={navDetails.clickedData?.selected?.sg_id}
                      categoryId={navDetails.clickedData?.cgy_id}
                      weekList={weekList}
                      onChangeType={onChangeType}
                      shift_admin={shift_admin}
                      sgLoading={sgLoading}
                      accEmp={accEmp}
                    />
                  </div>
                )}
              </div>
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardScreen;
