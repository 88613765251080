export const shiftOverview = [
    {
      day: "Mo",
      id:1
    },
    {
      day: "Tu",
      id:2
    },
    {
      day: "We",
      id:3
    },

    {
      day: "Th",
      id:4
    },

    {
      day:"Fi",
      id:5
    },
    {
      day: "Sa",
      id:6
    },
    {
      day: "Su",
      id:7
    },
  ];