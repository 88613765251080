import Stylesheet from "./style.module.scss";

export const styles: any = {
  input: {
    sm: {
      className: Stylesheet.input_sm,
    },
  },
  label: {
    sm: {
      className: Stylesheet.label_sm,
    },
    sm_border:{
      className: Stylesheet.label,
    }
  },
  sx: {
    sm: {
      "& fieldset": { fontSize: "0.75em" },
    },
    sm_border:{
      "& fieldset": { border: 'none' },
    }
  },
};