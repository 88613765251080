//react-hooks
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//styles
import Stylesheet from "./style.module.scss";

//dayjs
import { getDateLocaleString } from "../../../../utils/dayjs";
import dayjs from "dayjs";

//components
import SyIcons from "../../../sy-icons";
import ShiftCard from "../../../../../projects/shift/modules/shift-module/components/shift-card";
import {useSelector} from "react-redux";

//types
import {props} from './week-sheduler-modal';
import { getNavDetails } from "../../../../../projects/simpler/feature/simpler-slice";

const WeeklySheduler = (props: props) => {
  const {
    currentWeek,
    shiftAdd,
    groupId,
    weekList,
    shiftEdit,
    shedulerList,
    shedulerChange,
    shift_admin
  } = props;
  
  
  const { t } = useTranslation();
  const navDetails = useSelector(getNavDetails);
  


  let daysOfWeek: any = [
    { day: "Mon" },
    { day: "Tue" },
    { day: "Wed" },
    { day: "Thu" },
    { day: "Fri" },
    { day: "Sat" },
    { day: "Sun" },
  ];
  let timesOfDay: any = [
    { start: "00:00", end: "05:59" },
    { start: "06:00", end: "11:59" },
    { start: "12:00", end: "17:59" },
    { start: "18:00", end: "23:59" },
  ];

  let slotData: any = {
    Mon: [[], [], [], []],
    Tue: [[], [], [], []],
    Wed: [[], [], [], []],
    Thu: [[], [], [], []],
    Fri: [[], [], [], []],
    Sat: [[], [], [], []],
    Sun: [[], [], [], []],
  };


  const startOfWeek = dayjs().week(currentWeek).startOf("week");

  const getDaysOfWeek = daysOfWeek.map((data: any, index: any) => {
    return {
      ...data,
      weekFormat: startOfWeek.add(index + 1, "day").format("dddd").toLocaleLowerCase(),
      dateFormat: startOfWeek.add(index + 1, "day").format("DD.MM.YYYY"),
      date: startOfWeek.add(index + 1, "day").format("YYYY-MM-DD"),
      shedulerChange: shedulerChange,
    };
  });

  const disabled = navDetails.clickedData.selected?.sg_id ? true : false ;


  const getSlotData = (slotData: any) => {
    
    slotData = { ...slotData };
    
    shedulerList?.map((sheduleweekList:any)=>{
      return {
        ...sheduleweekList, 
        ss_start: new Date(sheduleweekList.ss_start),
        ss_end: new Date(sheduleweekList.ss_end),
      }
    })
    .map((data: any) => {
      if (!slotData[`${getDateLocaleString(data.ss_start, "weekDay")}`]) {
        slotData[`${getDateLocaleString(data.ss_start, "weekDay")}`] = [];
      }

      const setIndex = timesOfDay.findIndex((value: any, index: any) => {
        return (
          value.start <= getDateLocaleString(data.ss_start, "time") &&
          value.end >= getDateLocaleString(data.ss_start, "time")
        );
      });

      slotData[`${getDateLocaleString(data.ss_start, "weekDay")}`][
        setIndex
      ].push(data);
      return data;
    });
    return slotData;
  };

  useEffect(() => {
    weekList(getDaysOfWeek);
  }, [currentWeek]);

  const onIconClick = (val: any) => {
    shiftAdd(val);
  };

  const onCardClick = (val: any) => {
    shiftEdit(val);
  };

  const TableHead = (item: any) => {
    return (
      <tr className={`${Stylesheet.heading_tr} pb-5 `}>
        <p className={`${Stylesheet.week}`}>{t(item.item?.weekFormat)}</p>
        <p className={`${Stylesheet.day} opacity-50`}>{item.item.dateFormat}</p>
      </tr>
    );
  };
  const TableRowBody = ({ item, daysOfWeek }: any) => {
    return daysOfWeek.map((value: any, colIndex: any) => (
      <td key={colIndex} className={`${Stylesheet.icon_data} `}>
        {item.item[value.day].map((val: any) => {
          const currentDate = getDateLocaleString(new Date(), "year");
          return val.length === 0 ? (
            <div
              className={`${groupId ? Stylesheet.groupSlot : Stylesheet.slot} `}
            >
              <span onClick={() => onIconClick(value)}>
                {
                  shift_admin &&
                    <SyIcons
                    name={"add_circle_outline"}
                    className={`${
                      
                      currentDate <= value.date && disabled
                        ? Stylesheet.arrowIcon
                        : Stylesheet.disableIcon
                    } `}
                    iconType="icons"
                    
                  />
                }
              </span>
            </div>
          ) : (
            val.map((val: any) => {
              const disable = getDateLocaleString(new Date(), "utcFormate") <= getDateLocaleString(val.ss_start, "utcFormate")  ? false : true;
              return (
                <div
                  onClick={() => onCardClick({ val, disable })}
                  className={`${
                    groupId ? Stylesheet.groupSlot : Stylesheet.slot
                  } `}
                >
                  <ShiftCard
                    shiftData={val}
                    groupId={groupId}
                    disable={disable}
                  />
                </div>
              );
            })
          );
        })}
      </td>
    ));
  };
  const TableColBody = (item: any) => {
    return (
      <tr className={`${Stylesheet.header_tr}`}>
        <TableRowBody item={item} daysOfWeek={getDaysOfWeek} />
      </tr>
    );
  };
  return (
    <>
      <table className={`${Stylesheet.table} w-100 `}>
        <thead className={`${Stylesheet.thead} `}>
          {getDaysOfWeek.map((item: any, index: any) => (
            <TableHead item={item} />
          ))}
        </thead>
        <tbody>
          <TableColBody item={getSlotData(slotData)} />
        </tbody>
      </table>
    </>
  );
};
export default WeeklySheduler;
