export const selectableColors = [
  {
    id: 1,
    color: "#011587",
  },
  {
    id: 2,
    color: "#178DC3",
  },
  {
    id: 3,
    color: "#90D4EC",
  },
  {
    id: 4,
    color: "#4FA722",
  },
  {
    id: 5,
    color: "#9AC00B",
  },
  {
    id: 6,
    color: "#DFDB07",
  },
  {
    id: 7,
    color: "#9A055E",
  },
  {
    id: 8,
    color: "#F55985",
  },
];
