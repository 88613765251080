import { createSlice } from "@reduxjs/toolkit";

interface RootState {
  availableList: any;
  groupList: any;
  shiftList:any;
  shiftAccessList:any;
  memberList:any;
}
const initialState: RootState = {
  availableList: null,
  groupList: null,
  shiftList:null,
  shiftAccessList:null,
  memberList:null
};

export const schedulerSlice = createSlice({
  name: "scheduler",
  initialState,
  reducers: {
    setAvailableList: (state, { payload }) => {
      state.availableList = payload;
    },
    setGroupList: (state, { payload }) => {
      state.groupList = payload;
    },
    setShiftList: (state, { payload }) => {
        state.shiftList = payload;
      },
    setShiftAccessList:(state , {payload})=>{
       state.shiftAccessList = payload;
    },
    setMemberList:(state , {payload}) => {
      state.memberList = payload;
    }
  },
});

export const { setAvailableList, setGroupList ,setShiftList , setShiftAccessList, setMemberList} = schedulerSlice.actions;
export const getAvailableList = (state: any) =>state.schedulerReducer.availableList;
export const getGroupList = (state: any) => state.schedulerReducer.groupList;
export const getShiftList = (state: any) => state.schedulerReducer.shiftList;
export const getShiftAccessList = (state: any) => state.schedulerReducer.shiftAccessList;
export const getMemberList = (state:any) => state.schedulerReducer.memberList;


export default schedulerSlice.reducer;
