import http  from "../../../../../axios";

class getservice {
  saveShiftCategory(data: any) {
    return http("shift_url").post(`category/save/${data.id}`, data.payload);
  }

  deleteShiftCategory(id: any) {
    return http("shift_url").delete(`category/delete/${id}`);
  }

  getwildCard(data: any) {
    return http().post(
      `/public/support/wildcard/${data.searchValue}`,
      data.payload
    );
  }

  getCategoryGroup() {
    return http("shift_url").get(`/category/list`);
  }

  getShiftGroup(id: any) {
    return http("shift_url").get(`groups/getcategorygroups/${id}`);
  }

  getShiftEditCategory(id: any) {
    return http("shift_url").get(`category/get/${id}`);
  }

  shiftGroupSave(data: any) {
    return http("shift_url").post(`groups/save/${data.id}`, data.payload);
  }

  deleteShiftGroup(id: any) {
    return http("shift_url").delete(`groups/delete/${id}`);
  }

  getShiftGroupData(id: any) {
    return http("shift_url").get(`groups/get/${id}`);
  }
  saveShift(data: any) {
    return http("shift_url").post(`shifts/save${data.editShiftId?'/'+data.editShiftId:''}`, data.payload);
  }

  deleteShift(id: any) {
    return http("shift_url").delete(`shifts/delete/${id}`);
  }

  getGroups() {
    return http("shift_url").get(`/groups/list`);
  }

  getcategorygroups(id:any) {
    return http("shift_url").get(`/shifts/getcategorygroups/${id}`);
  }

  getShift(id:any) {
    return http("shift_url").get(`/shifts/get/${id}`);
  }

  getAvailableList(payload:any) {
    return http("shift_url").post(`/shifts/get_members_shift_day_wise`,payload);
  }

  getUserList(payload:any) {
    return http("shift_url").post(`shifts/members_list`,payload);
  }

  getShiftList(payload:any) {
    return http("shift_url").post(`shifts/list`,payload);
  }

  getExportList(payload:any){
    return http("shift_url").post(`excel/export/shifts`,payload);
  }

  getAccessMembers(payload:any){
    return http("shift_url").post(`shifts/myshifts`,payload);
  }

  getCategoryMemberList(payload:any){
    return http("shift_url").post(`shifts/get_category_members_shift`,payload)
  }

  validateMember(payload:any){
    return http("shift_url").post(`shifts/validate_members` ,payload);
  }
  
  getShiftAssignedData(payload:any){
    return http("shift_url").post(`shifts/shift_not_assigned`,payload)
  }

}

export default new getservice();
