import { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import DashboardScreen from "../modules/shift-module/screens/dashboard-screen";
import AppHeader from "../modules/common-module/component";
import Login from "../modules/auth-module/screens/login";
import AuthContext from "../context/auth-context";
import {
  clearAll,
  getCookie,
  getData,
  getJsonData,
} from "../../../shared/utils";
import {
  displayOverlayLoading,
  setTokenReducer,
  setUserDetailReducer,
} from "../feature/common-slice";
import { useSelector, useDispatch } from "react-redux";
import React, { Suspense, lazy } from "react";

import ShiftCard from "../modules/shift-module/components/shift-card";
import SyTimePicker from "../../../shared/components/forms/sy-time-picker";
import SyTimeRange from "../../../shared/components/forms/sy-timerange-picker";
import Overview from "../modules/shift-module/screens/dashboard-screen/components/overview";
import AvailableList from "../modules/shift-module/components/available-list";
import Preference from "../modules/shift-module/components/preference";
import { useMutation } from "@tanstack/react-query";
import RouteService from "./service";
import { useTranslation } from "react-i18next";
import config from "../../../environments/env-config";


const AppRoutes = () => {
  // const { authToken } = useContext(AuthContext);
  // const authToken:any = getData("jwt_token");
  const dispatchRed = useDispatch();
  const authoken = useSelector(setTokenReducer);
  // dispatchRed(setTokenReducer(await getData("jwt_token")))
  // const authToken = authoken.payload.userDetailReducer.token;

  const authToken = getCookie(config.auth_token);

  const { i18n } = useTranslation();

  

  const { mutate: getTranslation, isLoading: isTransLoading } = useMutation({
    mutationFn: RouteService.getTranslation,
    onSuccess: (translationData) => {
      const { data } = translationData || {};
      i18n.addResourceBundle("de", "translation", data, true);
      i18n.changeLanguage("de");
    },
  });

  const InitTranslation = async () => {
    const english_lan = await getData("tf");
    if (!english_lan) {
      getTranslation();
    }
  };

  const { mutate: getUserDetails, isLoading: isUserDetailLoading } =
    useMutation({
      mutationFn: RouteService.getUserDetails,
      onSuccess: (data) => {
        console.log(data.data , 'hhhhhhhhh');
        
        RouteService.setUserDetails(data.data);
        if (data?.data?.jwt_token) setDetailToRedcer(data.data);
      },
    });

  const setDetailToRedcer = async (data: any) => {
    dispatchRed(setUserDetailReducer(data.user_details));
  };

  const checkIsUserDataAvailable = async () => {
    const userDetail = await getJsonData("user_details");
    if (authToken && !userDetail) {
      getUserDetails();
    }
    if (!authToken) {
      clearAll();
    }
  };

  useEffect(() => {
    InitTranslation();
    checkIsUserDataAvailable();
  }, []);

  useEffect(() => {
    if (isUserDetailLoading || isTransLoading) {
      dispatchRed(displayOverlayLoading(2));
    } else {
      dispatchRed(displayOverlayLoading(0));
    }
  }, [isUserDetailLoading, isTransLoading]);

  if (isUserDetailLoading || isTransLoading) {
    return null;
  }

  return (
    <>
      {authToken ? (
        <>
          <AppHeader />
          <Routes>
            <Route path="/" element={<DashboardScreen />}></Route>
            <Route path="*" element={<DashboardScreen />}></Route>
            <Route path="/dashboard" element={<DashboardScreen />}></Route>
            <Route path="/dani" element={<ShiftCard />}></Route>
            <Route path="/overview" element={<Overview />}></Route>
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/" element={<Login />}></Route>
        </Routes>
      )}{" "}
    </>
    // <>
    //   <>
    //     {/* <AppHeader />
    //     <Routes>
    //       <Route path="/" element={<DashboardScreen />}></Route>
    //     </Routes> */}
    //   </>
    //   <>
    //     <Routes>
    //       <Route path="/" element={<Login />}></Route>
    //     </Routes>
    //   </>
    // </>
  );
};
export default AppRoutes;
