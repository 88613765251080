// import http, { https } from "../../../axios";
import http from "../../../../../axios";


class AuthService {
    signIn(data:any) {
        return http().post('public/a/login', data)
    }
    signOut(data:any){
        return http().post('public/common/logout', data)
    }
}
export default new AuthService();