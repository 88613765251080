import dayjs from "dayjs";
import { useState } from "react";
import { number } from "yup";
const dateFormat: any = {
  date: "DD.MM.YYYY",
  month: "MM.DD.YYYY",
  year: "YYYY-MM-DD",
  dateTime: "YYYY-MM-DDTHH:mm",
  setdate: "DD",
  setdateFormate:'D',
  time: "HH:mm",
  weekDay:'ddd',
  timeWithSecond: "HH:mm:ss",
  timeWithSection: "HH:mm:A",
  dateFormat: "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
  utcFormate :'YYYY-MM-DD HH:mm:ss [GMT]Z'
};
export const getDateLocaleString = (date: any, mode: string) => {
  const format = dateFormat[mode];
  if (date) {
   
    return dayjs(date).format(format);
  }
  return "";
};
export const convertMinsToHrsMins = (value: any) => {
  const hours = Math.floor(value / 60);
  const minutes = Math.round(value % 60);
  if (hours > 0 && minutes > 0) {
    return `${hours}:${minutes}`;
  } else if (hours > 0) {
    return `${hours}:00`;
  } else if (hours === 0) {
    return `00:${minutes}`;
  } else {
    return 0;
  }
};
export const getTimeDifference = (time: any, range: any) => {
  const startTime = getDateLocaleString(time, "time");
  let Range: any = range;
  if (!range.includes(":")) {
    const data = convertMinsToHrsMins(range);
    Range = data;
  }
  if (Range.includes(":")) {
    const data = startTime.split(":");
    const timeRange = Range.split(":");
    let hour: any = parseInt(data[0]) + parseInt(timeRange[0]);
    let minute: any = parseInt(data[1]) + parseInt(timeRange[1]);
    if (minute < 10) {
      minute = "0" + minute;
    }
    if (minute > 59) {
      const data = minute - 60;
      minute = "0" + data;
      hour = hour + parseInt(timeRange[0]);
    }
    if (hour > 24) {
      const data = hour - 24;
      hour = data;
    }
    if (hour == 24) {
      hour = "00";
    } else if (hour < 10) {
      hour = "0" + hour;
    }
    const updateTime = hour + ":" + minute;
    const updateStartTime = getDateLocaleString(time, "year");
const setdateFormate =dayjs(`${updateStartTime}T${updateTime}`)
    return setdateFormate;
  } else {
    return "";
  }
};
export const setDate = (date: string, mode: number) => {
  if (date) {
    return dayjs(date).add(mode, "month");
  }
  return "";
};
export const getUTCTime = (date:any,time:any) => {

 console.log(date,'date')
 console.log(time,'time')
const setTime =getDateLocaleString(time,'time')

const splitTime = setTime?.split(":");
 const updatetime =dayjs(date).set('hour', parseInt(splitTime[0])).set('minute', parseInt(splitTime[1])).set('second', 0)

 console.log(updatetime,'updatetime')
const utcTime: any = { ...updatetime };
 const newDate = new Date(utcTime.$d)

 const utcDate = newDate.toISOString();
 console.log(utcDate,'utcDate')
 return utcDate;
};
export const currentTime =()=>{
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const railwayTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  return railwayTime
}
export const timeValidation =(date:any)=>{
  const timeRange = date.split(":");
  const startTimeValidation = dayjs().set('hour', parseInt(timeRange[0]) ).set('minute',  parseInt(timeRange[1])).startOf('minute');
  return startTimeValidation
}