import {
  LocalizationProvider,
  MobileTimePicker,
  TimeClock,
  TimeField,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stylesheet from "./style.module.scss";
import { useRef, useState } from "react";
import { styles } from "./config";
import dayjs from "dayjs";

type Props = {
  reference?: any;
  lable?: any;
  onChangeValue?: any;
  defaultValue?: any;
  handleOKClick?: any;
  onfocus?: any;
  value?: any;
  type?: "picker" | "text";
  fieldSize?: "sm" | "md" | "sm_border" | "sm_lable"|'sm_holder';
  handleOnClick?:any
  handleOnBlur?:any
  minValue?:any
};

const SyTimePicker = (props: Props) => {
  const {
    reference,
    lable = "timePicker",
    onChangeValue,
    defaultValue,
    handleOKClick,
    handleOnClick,
    handleOnBlur,
    value,
    type,
    minValue,
    fieldSize = "md",
  } = props;


 
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {type === "picker" ? (
          <MobileTimePicker
            label={lable}
            ref={reference}
            onChange={onChangeValue}
            ampm={false}
            defaultValue={defaultValue}
            value={value}
            onAccept={handleOKClick}
            minTime={minValue}
          />
        ) : (
          <TimeField
            label={lable}
            ref={reference}
            onChange={onChangeValue}
            ampm={false}
            defaultValue={defaultValue}
            value={value}
            sx={styles.sx[fieldSize]}
            inputProps={styles.input[fieldSize]}
            InputLabelProps={styles.label[fieldSize]}
            onBlur={handleOnBlur}
            onFocus={handleOKClick}
            onClick={handleOnClick}
            minTime={minValue}

            
          />
        )}
      </LocalizationProvider>
    </>
  );
};
export default SyTimePicker;
