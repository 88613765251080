//react hooks
import { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

//style
import Stylesheet from "./style.module.scss";

//redux-slice
import { getAvailableList } from "../../../../feature/shift-slice/scheduler-slice";

//utils
import { getDateLocaleString } from "../../../../../../shared/utils/dayjs";

//model
import { Props } from "./available-list-model";

//components
import Avathar from "../avathar/avathar";
import SyChip from "../../../../../../shared/components/sy-chip";
import SyCollapse from "../../../../../../shared/components/sy-collapse";
import { log } from "console";
import { SyIcons, SyLoadingIcon } from "../../../../../../shared/components";

const AvailableList = (props: any) => {
  const { availableLoading } = props;

  const { t } = useTranslation();

  const availableList = useSelector(getAvailableList);

  const [listOpen, setListOpen] = useState<any>(null);

  useEffect(() => {
    if (availableList) {
      const defaultList = availableList?.find((option: any) => option);
      setListOpen(defaultList);
    }
  }, [availableList]);

  const onListShow = (val: any) => {
    setListOpen(val);
  };

  return (
    <>
      {availableLoading ? (
        <div className={`${Stylesheet.head2}`}>
          <div className=" d-flex justify-content-center align-items-center m-5">
            <SyLoadingIcon size="sm" />
          </div>
        </div>
      ) : availableList?.length > 0 ? (
        <div className={`${Stylesheet.head2} sy_vertical_scroll`}>
          {availableList?.map((data: any, index: number) => (
            <div key={index} className={`${Stylesheet.hrHead} mt-2 mb-2`}>
              <div
                className={`d-flex justify-content-between g-0 pt-1 p-3 pb-0 pointer`}
                onClick={() => onListShow(data)}
              >
                <div className={`${Stylesheet.avatarName} sm-font`}>
                  <Avathar
                    type="single"
                    profile={data}
                    size={"md"}
                    showName={true}
                  />
                </div>
                 {/* pls don't delete this code this is arrow for joel confirmation */}

                  {/* {listOpen?.id != data?.id &&
                    <div className="d-flex">
                      <SyIcons name="keyboard_arrow_down" className={"pointer"} />
                    </div>
                  } */}
                  {data.actual_time > 0 &&
                    <div className="sm-font">
                      <SyChip
                        label={`${data?.alloted_time}/${data?.actual_time ? data?.actual_time : "0"}h`}
                        color={"#c7eabb"}
                        size="md"
                      />
                    </div>
                  }

              </div>
              <SyCollapse statement={listOpen?.id == data?.id}>
                {data?.shift?.map((shiftData: any, shiftIndex: number) => (
                  <div key={shiftIndex}>
                    {shiftData?.values?.length !== 0 && (
                      <div className={`pt-0 pb-0 p-3`}>
                        <span className="opacity-50 ms-3 sm-font">
                          {shiftData.day}
                        </span>
                        <br />
                        {shiftData?.values?.map((valueData: any, valueIndex: number) => (
                          <div
                            key={valueIndex}
                            className="d-flex ms-3 justify-content-between mb-1"
                          >
                            <span className="sm-font">
                              {getDateLocaleString(valueData.start, "time")}-
                              {getDateLocaleString(valueData.end, "time")}
                            </span>
                            <div className="d-flex">
                              <SyChip label={"available"} color={"#c7eabb"} size="md" />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </SyCollapse>
              <hr className={`${Stylesheet.tag} m-1`} />
            </div>
          ))}
        </div>
      ) : (
        <div className={`${Stylesheet.noData}`}>
          <p>{t("no_data_found")}</p>
        </div>
      )}
    </>
  );
};

export default AvailableList;
