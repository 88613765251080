import Stylesheet from "./style.module.scss";

export const styles: any = {
  input: {
    sm: {
      className: Stylesheet.input_sm,
    },
    sm_border:{
        className: Stylesheet.input_sm_border,
        
      },
      sm_lable:{
        className: Stylesheet.input_sm_border,
        
      },
     
  },
  label: {
    sm: {
      className: Stylesheet.label_sm,
    },
    sm_border:{
      className: Stylesheet.label, 
    },
    sm_lable:{
      className: Stylesheet.label_fix, 
    },
    sm_holder:{
      className: Stylesheet.sm_holder, 
    }
   
  },
  sx: {
    sm: {
      "& fieldset": { fontSize: "0.75em" },
    },
    sm_border:{
      "& fieldset": { border: 'none' },
    
    },
    sm_lable:{
      "& fieldset": { border: 'none' },
    
    },
    sm_holder:{
      "& fieldset": { border: 'none' },
    
    }

  
    
  },
};
