import React from 'react';

const defaultValue = {
  authToken: null || '',
  isAppReady: false}

// Auth context holds app ready and auth token values
const AuthContext = React.createContext(defaultValue);


export default AuthContext;