import Avathar from "../../components/avathar/avathar";
import SyChip from "../../../../../../shared/components/sy-chip";
import { InputForm } from "../../../../../../shared/components/forms";
import SyIcons from "../../../../../../shared/components/sy-icons";
import styleSheet from "./style.module.scss";
import { useState } from "react";
import { ClickAwayListener } from "@mui/material";
import { Controller } from "react-hook-form";
import { UserListProps } from "./shift-category-interface";
import SyTooltip from "../../../../../../shared/components/sy-tooltip";
import { useTranslation } from "react-i18next";

const UserList = (props: UserListProps) => {
  const {
    data,
    index,
    onDeleteClick,
    control,
    name,
    showAllottedTime,
    errors,
  } = props;

  const {t} = useTranslation();

  const [chipValue, setChipValue] = useState(data.alloted_time);
  const [view, setView] = useState<"chip" | "field">(
    !chipValue ? "field" : "chip"
  );

  const handleDoubleClick = () => {
    setView("field");
  };

  const handleOnClickAway = () => {
    if (chipValue && !errorMessage) {
      setView("chip");
    }
  };

  const handleDeleteClick = (id: number) => {
    onDeleteClick({ data: data.user, id });
  };

  const onChangeHours = (data: any, onChange: any) => {
    onChange(data);
    setChipValue(data.target.value);
  };
  const errorMessage: string | undefined =
    errors?.[index]?.alloted_time?.message;

  const handleOnKeyDown = (e: any) => {
    if (e.key === "Enter") {
      document.getElementById(`${index}`)?.blur();
      handleOnClickAway();
    }
  };

  return (
    <div className="d-flex flex-row pt-1">
      <div className="col-6 d-flex align-items-center">
        <Avathar profile={data.user ? data.user : data} showName={true} />
      </div>
      <div className="col-5">
        {/* {showAllottedTime && ( */}
          <div className={`d-flex justify-content-end ${showAllottedTime  ? "" : "d-none"}`}>
            {view === "chip" && (
              <div>
                <SyChip
                  color={"#44B81C4D"}
                  label={chipValue}
                  className={styleSheet.chip}
                  onDoubleClickChip={handleDoubleClick}
                />
              </div>
            )}
            <ClickAwayListener onClickAway={handleOnClickAway}>
              <div
                className={`d-flex  justify-content-end ${
                  view === "chip" && "d-none"
                }`}
                onKeyDown={handleOnKeyDown}
              >
                <Controller
                  control={control}
                  name={name}
                  render={({ field: { onChange, onBlur } }) => {
                    return (
                      <div className="w-75 me-1">
                        <InputForm
                          key={data.id}
                          type={"number"}
                          id={`${index}`}
                          defaultValue={data.alloted_time}
                          onInputChange={(e) => onChangeHours(e, onChange)}
                          placeholder={t("allotted_time_per_week")}
                          onBlur={onBlur}
                          fieldSize="sm"
                          errorField={!!errorMessage}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </ClickAwayListener>
            <SyTooltip title={errorMessage}>
              <div
                className={`d-flex align-items-center ${styleSheet.warning_icon_div} `}
              >
                <SyIcons
                  name="error_outline"
                  className={`pointer ${styleSheet.warning_icon} ${
                    !errorMessage && "d-none"
                  } `}
                />
              </div>
            </SyTooltip>
          </div>
        {/* )} */}
      </div>
      <div className="col-1 d-flex align-items-center justify-content-center">
        <SyIcons
          name="close"
          value={index}
          className={styleSheet.delete_icon}
          onClick={handleDeleteClick}
        />
      </div>
    </div>
  );
};

export default UserList;
