export const getShiftParamPayload = (value: any) => {
  const payload: any = {};
  const parts = value.split("/");
  parts.forEach((part: { split: (arg0: string) => [any, any] }) => {
    const [key, val] = part.split("=");
    if (key && val) {
      payload[key] = val;
    } else if (part) {
      payload["cgy"] = part;
    }
  });
  const url = `${payload ? `cgy=${payload?.cgy}` : ""}${
    payload?.sg ? `/sg=${payload?.sg}` : ""
  }`;
  const obj = { url: url, payload: payload };
  return obj;

  // return `${payload ? `cgy=${payload?.cgy}`:''}${payload?.sg ? `/sg=${payload?.sg}` : '' }`;
  //   return payload;
};

export const getParamValue = (searchParams: any) => {
  return searchParams.get("cgy") && /sg/.test(searchParams.get("cgy"))
    ? getShiftParamPayload(searchParams.get("cgy")).payload
    : { cgy: searchParams.get("cgy") };
  // sg: searchParams.get("sg") ? searchParams.get("sg") : null,
};

