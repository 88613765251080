import { createSlice } from "@reduxjs/toolkit";

interface RootState {
  overviewList: any;
  overviewLoading: boolean;
}
const initialState: RootState = {
  overviewList: "",
  overviewLoading: false,
};

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    setOverviewList: (state, { payload }) => {
      state.overviewList = payload;
      state.overviewLoading = false;
    },
    setOverviewLoading: (state, { payload }) => {
      state.overviewList = [];
      state.overviewLoading = true;
    },
    resetOverviewSlice: () => initialState,
  },
});

export const { setOverviewList, setOverviewLoading, resetOverviewSlice } =
  overviewSlice.actions;

export const getOverviewList = (state: any) =>
  state.overviewReducer.overviewList;

export const getOverviewLoading = (state: any) =>
  state.overviewReducer.overviewLoading;

export default overviewSlice.reducer;
