export const status = [
  {
    value: 1,
    label: "active",
  },
  {
    value: 2,
    label: "inactive",
  },
];

export const shift_type = [
  { id: 1, label: "General" },
  { id: 2, label: "Client" },
];

export const defaultValues = {
  // sg_type: shift_type[0],
  sg_name: "",
  // sg_ref: null,
  sg_desc: null,
  sg_color: "#1A9DD9",
  sg_status: status[0],
  // sg_req_hrs: 0,
  sg_category: null,
  // sg_exc: 0,
};
