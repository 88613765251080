export const type_id_icon: any = {
  101: "mood",
  102: "apartment",
  103: "emoji_emotions",
  104: "business",
  105: "contact_phone",
  3: "drive_eta",
  2: "meeting_room",
  4: "devices",
  99: "person",
};

export const wildcardData: any = [
  {
    id: "3",
    manual_id: "3",
    name: "contact  3",
    region: "1",
    sub: "0",
    type: "contact",
    type_id: "99",
  },
];
