import { configureStore } from "@reduxjs/toolkit";
import {overlayLoaderSlice} from "./common-slice";
import {userDetailsSlice} from "./common-slice";
import {navSlice,overviewSlice,schedulerSlice} from "./shift-slice"
import { paramSlice } from "./common-slice";

export const store = configureStore({
  reducer: {
    overLayLoading: overlayLoaderSlice,
    userDetailReducer:userDetailsSlice,
    navReducer:navSlice,
    paramReducer:paramSlice,
    overviewReducer:overviewSlice,
    schedulerReducer:schedulerSlice
  },
});

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch
