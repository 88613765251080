import styleSheet from "./style.module.scss";
import { primaryColor } from "../../constants/theme";
import { getTextColor } from "../../utils/theming";

interface ColoredLabelprops {
  label?: string | undefined | null;
  color?: string | undefined | null;
  actionIcon?: boolean;
  size?: "sm" | "md" | "lg";
  type?: "square" | "rounded_Pill" | "rounded_circle"|"rounded_square";
  onClick?: any;
  onClickChip?: any;
  onDoubleClickChip?: any;
  className?: any;
  value?: any;
  labelIcon?: any;
}

const SyChip = (props: ColoredLabelprops) => {
  const {
      label,
      color,
      actionIcon,
      value,
      onClick,
      onClickChip,
      onDoubleClickChip,
      labelIcon,
      className = "",
      size = "md",
      type = "square",
    } = props,
    COLOR = color ? color : primaryColor,
    chipStyle = {
      backgroundColor: `${COLOR}`,
      color: `${getTextColor(COLOR)}`,
    };

    const onClickIcon = () => {
      if (onClick) onClick(value);
    };
  
    const onChipClick = () => {
      if (onClickChip) onClickChip(value);
    };

  return (
    <>

      {label || labelIcon || type === "rounded_square"? (
        <div
          className={`${styleSheet.badge} ${styleSheet[size]} ${
            styleSheet[type]
          } ${
            type === "rounded_circle" ? styleSheet[type + "_" + size] : ``
          } ${className} `}
          
          style={chipStyle}
          onClick={onChipClick}
          onDoubleClick={onDoubleClickChip}
        >
          {label ?<p
            className={`align-items-center ellipsis ${styleSheet.font_size} m-0`}
          >
            {label}
          </p>: null}
          {labelIcon ? labelIcon : null}
          {actionIcon ? (
            <span
              className={`material-icons-round d-flex align-items-center  pointer select-none ${styleSheet.closeIcon}`}
              onClick={onClickIcon}
            >
              cancel
            </span>
          ) : null}
        </div>
      ) : (
        void null
      )}
    </>
  );
};

export default SyChip;

// {label || labelIcon ? (
//   <div
//     className={`${className} ${styleSheet[size]} ${styleSheet[type]} ${
//       styleSheet.badge
//     } ${type === "rounded_circle" ? styleSheet[type + "_" + size] : ``}`}
//     style={chipStyle}
//     onClick={onChipClick}
//     onDoubleClick={onDoubleClickChip}
//   >
//     {label && (
//       <p
//         className={`align-items-center ellipsis ${styleSheet.font_size} m-0`}
//       >
//         {label}
//       </p>
//     )}
//     {labelIcon ? labelIcon : null}
//     {actionIcon ? (
//       <span
//         className={`material-icons-round d-flex align-items-center  pointer select-none ${styleSheet.closeIcon}`}
//         onClick={onClickIcon}
//       >
//         cancel
//       </span>
//     ) : null}
//   </div>
// ) : (
//   void null
// )}
