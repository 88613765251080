import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getMemberList } from "../../../../feature/shift-slice/scheduler-slice";
import Stylesheet from "./style.module.scss";
import Avathar from "../avathar/avathar";
import { SyChip, SyLoadingIcon } from "../../../../../../shared/components";
import { t } from "i18next";



const MemberList = (props: any) => {
    const { memberLoading } = props;


    const memberList = useSelector(getMemberList);
    const [listOpen, setListOpen] = useState<any>(null);

    useEffect(() => {
        if (memberList) {
            const defaultList = memberList?.find((option: any) => option);
            setListOpen(defaultList);
        }
    }, [memberList]);

    const onListShow = (val: any) => {
        setListOpen(val);
    };

    return (
        <>
            {memberLoading ? (
                <div className={`${Stylesheet.head2}`}>
                    <div className="d-flex justify-content-center align-items-center m-5">
                        <SyLoadingIcon size="sm" />
                    </div>
                </div>
            ) : memberList?.length > 0 ? (
                <div className={`${Stylesheet.head2} sy_vertical_scroll`}>
                    {memberList?.map((data: any, index: number) => (
                        <div key={index} className={`${Stylesheet.hrHead} mt-2 mb-2`}>
                            <div
                                className={`d-flex justify-content-between g-0 pt-1 p-3 pb-0 pointer`}
                                onClick={() => onListShow(data)}
                            >
                                <div className={`${Stylesheet.avatarName} sm-font`}>
                                    <Avathar
                                        type="single"
                                        profile={data}
                                        size={"md"}
                                        showName={true}
                                    />
                                </div>
                                {data.alloted_time && (
                                    <div className="sm-font">
                                        <SyChip
                                            label={`${data?.alloted_time}h`}
                                            color={"#c7eabb"}
                                            size="md"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className={`${Stylesheet.noData}`}>
                    <p>{t("no_data_found")}</p>
                </div>
            )}
        </>
    )
}
export default MemberList;