//react hooks
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//styles
import styleSheet from "./style.module.scss";

//service
import shiftService from "../../service/shift-service";

//helper
import {
  getMembers,
  getSavedMembers,
  getStatus,
  getStatusLabel,
} from "./helper";

//redux-slice
import { displayOverlayLoading } from "../../../../feature/common-slice";
import {
  getNavDetails,
  onCatogryClick,
  setGroupList,
  updateMasterData,
} from "../../../../feature/shift-slice";

//forms
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//components
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import {
  InputForm,
  SyToggleButton,
} from "../../../../../../shared/components/forms";
import SyTextEditor from "../../../../../../shared/components/forms/sy-rich-text-edit";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import { wildcard } from "../../screens/dashboard-screen/config";
import UserList from "./user-list";
import {
  ShiftCategoryProps,
  categoryFormValues,
} from "./shift-category-interface";
import { categorySchema } from "../../components/schema-validation/schema-validation";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import { type_id_icon, wildcardData } from "./shift-category-config";
import SyIcons from "../../../../../../shared/components/sy-icons";
import SyconfirmPopup from "../../../../../../shared/components/sy-confirmPopup";
import { getShiftDashBoardParamPayload, setQueryParams } from "../../../../../../shared/utils/helper";


const ShiftCategoryManage = (props: ShiftCategoryProps) => {
  const { close, editData, fetchList, statusOptions } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navDetails = useSelector(getNavDetails);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [employee, setEmployee] = useState(null);
  const [deletableItem, setDeletableItem] = useState<any>(null);
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const formDefaultValues = {
    cgy_name: "",
    cgy_desc: "",
    members: [],
    cgy_time_bound: false,
    cgy_status: statusOptions[0],
  };

  const {
    handleSubmit,
    control,
    setValue,
    setFocus,
    register,
    formState: { errors },
  } = useForm<categoryFormValues>({
    mode: "all",
    defaultValues: formDefaultValues,
    shouldUnregister: true,
    resolver: yupResolver<any>(categorySchema),
  });

  const { mutate: shiftListEdit, isLoading: isLoading } = useMutation({
    mutationFn: shiftService.getShiftEditCategory,
    onSuccess(data: any) {
      changeFormValues(data.data);
    },
  });

  useEffect(() => {
    setFocus("cgy_name");

    editData && shiftListEdit(editData);
  }, []);

  const changeFormValues = (editData: any) => {
    setValue("cgy_name", editData.cgy_name);
    setValue("cgy_desc", editData.cgy_desc);
    setValue("cgy_status", getStatus(editData.cgy_status, statusOptions));
    setValue("cgy_time_bound", editData.cgy_time_bound === 1);
    setValue("members", getMembers(editData.members));
    setIsEnabled(editData.cgy_time_bound === 1);
  };

  useEffect(() => {
    dispatch(displayOverlayLoading(isLoading ? 1 : 0));
  }, [isLoading]);

  const getUpdatedData = (variables: any, navDetails: any) => {
   
    if (variables.id) {
      const index = navDetails.findIndex(
        (data: any) => data.cgy_id === variables.id
      );
      const cgy = { ...navDetails[index] };
      cgy.cgy_name = variables.payload.default.cgy_name;
      navDetails[index] = cgy;
    } else {
      fetchList();
    }
    return navDetails;
  };

  const { mutate: saveMutation, isLoading: saveLoading } = useMutation({
    mutationFn: shiftService.saveShiftCategory,
    onSuccess(data, variables) {

      const cgyList = [...navDetails.master];
      const cgySelect = {
        cgy_name: variables.payload.default.cgy_name,
        cgy_id: data?.data?.id,
      };
      !editData && dispatch(setGroupList([]))
      !editData && dispatch(onCatogryClick(cgySelect));
      dispatch(updateMasterData(getUpdatedData(variables, cgyList)));
     
      setQueryParams(getShiftDashBoardParamPayload(`cgy=${ data?.data?.id}`));
      SyToast(data.data.message, "top-right", "short", "success");
      close();
    },
  });

  const {
    data: memberlist,
    mutate: getMemberList,
    isLoading: membersLoading,
  } = useMutation({
    mutationFn: shiftService.getwildCard,
  });

  useEffect(() => {
    getMemberList({ searchValue: "", payload: wildcard });
  }, []);

  const {
    fields,
    append: addNewAssignee,
    remove: deleteAssignee,
  } = useFieldArray({
    control: control,
    name: "members",
  });

  const save = (saveData: any) => {
    saveData.cgy_status = saveData.cgy_status.value;
    saveData.cgy_time_bound = saveData.cgy_time_bound ? 1 : 2;
    saveData.members = getSavedMembers(saveData.members);
    // !editData?.cgy_id ? delete saveData.cgy_status : void null;
    const payload = {
      default: {
        ...saveData,
      },
    };
    saveMutation({ id: editData ? editData : "", payload });
  };

  const handleFormSubmit = (data: any) => {
    save(data);
  };

  // useEffect(() => {
  //    errors.members?.message &&
  //     SyToast(errors.members.message, "top-right", "short", "error");
  // }, [errors]);

  const handleMemberLabel = (data: any) => data.name;
  const handleParentFilter = (data: string) => {
    getMemberList({ searchValue: data, payload: wildcard });
  };

  const handleToggleChanged = (newState: any) => {
    setIsEnabled(newState.target.checked);
    setValue("cgy_time_bound", newState.target.checked);
  };

  const handleChange = (data: any) => {
    const valueExist = fields.filter((field: any) => {
      if (field?.user?.id === data.id) {
        return field;
      }
      if (`${field?.user?.id}` === data.id) {
        return field;
      } else void null;
    });
    if (valueExist.length === 0) {
      addNewAssignee({ user: data, alloted_time: "" });
    } else {
      // SyToast("already_exists", "top-right", "short", "error");
    }
    setEmployee(null);
  };

  const getIcon = (data: any) => {
    return <SyIcons name={type_id_icon[data.type_id]} className={"me-3"} />;
  };

  const handleDeleteClick = (deletableItem: any) => {
    setDeletableItem(deletableItem);
    setOpenPopup(true);
  };

  const onConfirmDelete = async () => {
    deleteAssignee(deletableItem.id);
    setOpenPopup(false);
    await setTimeout(() => {
      errors.members?.message &&
        SyToast(errors.members.message, "top-right", "short", "error");
    }, 1);
  };

  const handleKeyDown = (e: any) => e.key === "Enter" && e.preventDefault();

  return (
    <>
      {openPopup && (
        <SyconfirmPopup
          open={openPopup}
          close={setOpenPopup}
          onConfirmClick={onConfirmDelete}
        />
      )}
      <form onSubmit={handleSubmit(handleFormSubmit)} onKeyDown={handleKeyDown}>
        <div className={`${errors?.cgy_name?.message ? "lh-1" : "pb-3"} mb-3`}>
          <Controller
            name="cgy_name"
            control={control}
            render={({ field }) => {
              return (
                <div onChange={field.onChange}>
                  <InputForm
                    {...field.onBlur}
                    type={"text"}
                    {...register("cgy_name")}
                    onChange={field.onChange}
                    placeholder={t("category_name")}
                    value={field.value}
                    error={errors?.cgy_name?.message}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className={`${errors?.cgy_status?.message ? "lh-1" : "pb-3"}`}>
          <Controller
            control={control}
            name="cgy_status"
            render={({ field: { onChange, value } }) => {
              return (
                <Dropdown
                  options={statusOptions}
                  controlledVal={value ? value : statusOptions[0]}
                  placeholder={t("status")}
                  handleChange={onChange}
                  getoptlabel={getStatusLabel}
                  clearIcon={true}
                  errorMessage={errors.cgy_status && errors.cgy_status.message}
                />
              );
            }}
          />
        </div>
        <div className="d-flex pb-3">
          <SyToggleButton value={isEnabled} toggleClick={handleToggleChanged} />
          <p className="ml-5 p-0 m-0">{t("alloted_time")}</p>
        </div>

        <div>
          <Dropdown
            options={memberlist?.data ? memberlist.data : []}
            // options={wildcardData}
            loading={membersLoading}
            placeholder={t("member")}
            blurOnSelect={true}
            controlledVal={employee}
            handleFilter={handleParentFilter}
            handleChange={handleChange}
            getoptlabel={handleMemberLabel}
            getIcon={getIcon}
            errorMessage={errors?.members?.message}
          />
          <div className="sy_horizontal_scroll pt-1">
            {fields.map((data: any, index: number) => {
              return (
                <div className={styleSheet.member_list} key={data.id}>
                  <UserList
                    data={data}
                    index={index}
                    showAllottedTime={isEnabled}
                    control={control}
                    onDeleteClick={handleDeleteClick}
                    name={`members.${index}.alloted_time`}
                    errors={errors.members}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <Controller
            name={"cgy_desc"}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <SyTextEditor
                  value={value}
                  onChange={onChange}
                  register={register("cgy_desc")}
                  placeholder={t("description")}
                />
              );
            }}
          />
        </div>
        <div className="d-flex justify-content-end mt-5 mb-2">
          <div>
            <Sybutton
              className={`Secondarybutton me-2 ${styleSheet.plan}`}
              label={t("close")}
              type={"reset"}
              onBtnClick={close}
              size={"md"}
            />
          </div>
          <Sybutton
            className={`primarybutton  me-2 ${styleSheet.plan}`}
            label={t("save")}
            type={"submit"}
            size={"md"}
            Loadings={saveLoading}
          />
        </div>
      </form>
    </>
  );
};

export default ShiftCategoryManage;
