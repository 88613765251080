import { useState } from "react";
import Chip from "../../sy-chip";
import styleSheet from "./style.module.scss";
import SyIcons from "../../../../shared/components/sy-icons";

interface colorPickerProps {
  defaultColor?: string | undefined;
  value?: string;
  displayText?: string | null;
  onColorChange?: (val: any) => void;
  type?: "round" | "square";
  editIcon?: boolean; // only use when type = "round"
  selected?: boolean;
}

const SyColorpicker = (props: colorPickerProps) => {
  const {
    displayText,
    onColorChange,
    type = "square",
    value = undefined,
    defaultColor = value ? undefined : "#1A9DD9",
    editIcon = false,
    selected,
  } = props;

  const [chipColor, setChipColor] = useState<string>(
    defaultColor || value || "#1A9DD9"
  );

  const onchange = (e: any) => {
    setChipColor(e.target.value);
    onColorChange && onColorChange(e);
  };

  const handleIconClick = () => {
    document.getElementById("syColorPicker")?.click();
  };

  return (
    <span className={`${styleSheet.colorpicker_outer}`}>
      <div
        className={`${
          selected || editIcon ? "position-relative" : ""
        } d-flex align-items-center`}
      >
        <input
          type="color"
          id="syColorPicker"
          className={`${styleSheet[`color_picker_${type}`]} pointer`}
          value={value}
          defaultValue={defaultColor}
          onChange={onchange}
        />
        {selected ? (
          <SyIcons
            iconType="icons"
            iconStyle="outlined"
            name={"check"}
            className={`${styleSheet.selected_icon} ${
              selected ? "" : "d-none"
            }`}
            onClick={handleIconClick}
          />
        ) : null}
        {editIcon && (
          <div
            className={`${styleSheet.picker_icon} d-flex justify-content-center align-items-center position-absolute `}
          >
            <SyIcons
              iconStyle="outlined"
              iconType="icons"
              name={"edit"}
              onClick={handleIconClick}
              className={` ${styleSheet.icon_style}`}
            />
          </div>
        )}
      </div>
      <div
        className={`${styleSheet.color_picker_text} d-flex align-items-center`}
      >
        <label htmlFor="syColorPicker">
          {displayText ? (
            <Chip label={displayText} color={chipColor} />
          ) : (
            void null
          )}
        </label>
      </div>
    </span>
  );
};

export default SyColorpicker;
