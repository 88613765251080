import { createSlice } from "@reduxjs/toolkit";
import { syWindowReload } from "../../../../shared/utils/sy-window";
import { setQueryParams } from "../../../../shared/utils";
import { getShiftDashBoardParamPayload } from "../../../../shared/utils/helper";

interface RootState {
  master: any[];
  clickedData: any;
  navListLoading: boolean;
  selectedData: any[];
}

const initialState: RootState = {
  master: [],
  clickedData: [],
  navListLoading: false,
  selectedData: [],
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    updateInitialNav: (state, { payload }) => {
      const path: any[] = [];
      const index = state.master.findIndex((val) => {
        return val.cgy_id == payload.cgy_id;
      });
      if (index >= 0) {
        state.master[index] = {
          ...payload,
          selected: state.master[index].selected,
        };
      } else {
        state.master.push(payload);
      }
      if (!payload?.selected) {
        state.master.forEach((val: any) => {
          if (val.selected) path.push(val.selected);
        });
        state.clickedData = path;
      }
    },
    onCatogryClick: (state, { payload }) => {
      const path: any[] = [];

      state.master = state.master.map((val) => {
        if (val.cgy_id === +payload.cgy_id) {
          path.push(payload);
          return { ...val, selected: payload };
        } else {
          path.push(val.selected);
          return val;
        }
      });
      state.clickedData = payload;
      state.selectedData = path.filter((item) => item !== undefined);
    },
    onShiftGroupClick: (state, { payload }) => {
      state.master.filter((val) => val.cgy_id === payload.sg_category);
      const updatedClickedData = { ...state.clickedData, selected: payload };
      state.clickedData = updatedClickedData;
      if (!state.clickedData.cgy_id) {
        state.clickedData["cgy_id"] = payload.sg_category;
      }
    },

    onShiftGroupDelete: (state, { payload }) => {
      state.clickedData.selected = null;
    },

    onChangeNav: (state, { payload }) => {
      const path: any = [];
      state.master = state.master
        .filter((val, index) => {
          if (
            (!payload?.index && !index) ||
            (payload?.index >= 0 &&
              payload?.index <= 2 &&
              index <= payload?.index + 1)
          ) {
            return true;
          }
          return false;
        })
        .map((val, index, arr) => {
          if (arr.length - 1 == index) {
            return { ...val, selected: "" };
          } else {
            path.push(val.selected);
            return val;
          }
        });
      state.clickedData = payload;
      state.selectedData = path;
    },
    updateMasterItems: (state, { payload }) => {
      // if (payload.mode === "add") {
      const IndexOfItem = state?.master[
        state.master.length - 1
      ].items.findIndex((val: any) => val.id === payload.data.id);
      if (IndexOfItem !== -1) {
        state.master[state.master.length - 1].items[IndexOfItem] = payload.data;
      } else {
        state.master[state.master.length - 1].items.push(payload.data);
      }
      state.clickedData[state.master.length - 1] = payload.data;
      state.master[state.master.length - 1].selected = payload.data;
      // } else {
      const masterIndex = state.master.findIndex(
        (val) => val.cgy_id == +payload.data.cgy_id
      );
      const itemIndex = state.master[masterIndex].items?.findIndex(
        (val: any) => val.sg_id == payload.data.sg_id
      );
      state.master[masterIndex].items[itemIndex] = payload?.data;
      state.master[masterIndex].selected = payload.data;
      state.clickedData[state.clickedData.length - 1] = payload.data;
      // }
    },

    delNavItems: (state, { payload }) => {

      const filteredItems = state.master.filter(
        (val: any) => val.cgy_id !== payload
      );

      const itemIndex = state.master.findIndex((val) => val.cgy_id === payload);

      if (itemIndex) {
        state.clickedData = { ...state.master[itemIndex - 1] };
        setQueryParams(getShiftDashBoardParamPayload(`cgy=${state.master[itemIndex - 1].cgy_id}`));
      } else {
        // setQueryParams(getShiftDashBoardParamPayload(`cgy=${0}`));
      state.clickedData = [];
      setQueryParams('');
      }
      state.master = filteredItems;
      // const path: any = [];
      // const masterIndex = state.master.findIndex(
      //   (val) => val.masterId == payload.masterId
      // );
      // const itemIndex = state.master[masterIndex].items.findIndex(
      //   (val: any) => val.id == payload.id
      // );
      // state.master.splice(masterIndex + 1);
      // state.master[masterIndex].items.splice(itemIndex, 1);
      // state.master[masterIndex].selected = "";
      // state.master.forEach((val: any) => {
      //   if (val.selected) {
      //     path.push(val.selected);
      //   }
      // });
      // state.clickedData = path;
    },
    setNavListLoading: (state, { payload }) => {
      state.navListLoading = payload;
    },
    updateMasterData: (state, { payload }) => {
      state.master = payload;
    },
    resetNavSlice: () => initialState,
  },
});

export const {
  updateInitialNav,
  onCatogryClick,
  onChangeNav,
  updateMasterItems,
  setNavListLoading,
  delNavItems,
  updateMasterData,
  onShiftGroupClick,
  onShiftGroupDelete,
  resetNavSlice,
} = navSlice.actions;

export const getNavDetails = (state: any) => state.navReducer;
export const getSelectedCategory = (state: any) => state.navReducer.selected;
export const getNavListLoading = (state: any) => state.navReducer.navListLoading;
export const getNavPath = (state: any) => state.navReducer.clickedData;

export default navSlice.reducer;
