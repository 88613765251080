// --- react_hooks --- //
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";

// --- styles --- //
import Stylesheet from "./style.module.scss";

// --- utils --- //
import {
  getDateLocaleString,
  getUTCTime,
  setDate,
} from "../../../../../../shared/utils/dayjs";
import { getAsyncWildData } from "../../../../../../shared/utils";

// --- services --- //
import shiftService from "../../service/shift-service";

// --- config --- //
import { dateRepeat, shiftStatus } from "./config";

// --- redux-slice --- //
import { getGroupList } from "../../../../feature/shift-slice/scheduler-slice";

// --- components --- //
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import SyChip from "../../../../../../shared/components/sy-chip";
import SyTextEditor from "../../../../../../shared/components/forms/sy-rich-text-edit";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import WeekPicker from "../../components/weekpicker";
import { InputForm, SyToggleButton } from "../../../../../../shared/components/forms";
import DatePicker from "../../../../../../shared/components/forms/date-picker";
import SyTimeRange from "../../../../../../shared/components/forms/sy-timerange-picker";
import SyAsynSelect from "../../../../../../shared/components/sy-asyn-select";
import Errormessage from "../../../../../../shared/components/forms/errormessage";
import Avathar from "../../components/avathar/avathar";
import SyIcons from "../../../../../../shared/components/sy-icons";
import { shiftCreat } from "../../components/schema-validation/schema-validation";
import SyconfirmPopup from "../../../../../../shared/components/sy-confirmPopup";
import SyCollapse from "../../../../../../shared/components/sy-collapse";

// --- interface --- //
import { shiftProps } from "./shift-interface";
import { getNavDetails, getSelectedCategory } from "../../../../feature/shift-slice";
import { getDataFromOptions } from "../create-shift-group-popup/helper";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import { displayOverlayLoading } from "../../../../feature/common-slice";
import { getSavedMember, getSavedMembers } from "../create-shift-category/helper";
import MemberList from "../../components/member-list";

type Props = {};

const ShiftPopup = (props: shiftProps) => {
  const { close, editShiftValues, defaultShiftDate, shift_admin, shedulerChange } = props;

  const dispatch = useDispatch();


  // --- useState --- //
  const [showDate, setShowDate] = useState(false);
  const [showDateField, setShowDateField] = useState("");
  const [members, setMembers] = useState<any>([]);
  const [endingTime, setEndingTime] = useState("");
  const [week, setweek] = useState([]);
  const [endRepeat, setEndRepeat] = useState("");
  const [editWeek, setEditWeek] = useState<any>();
  const [deletePopup, setDeletePopup] = useState<any>(false);
  const [deleteConfirmData, setDeleteConfirmData] = useState<any>();
  const [openconfirmPopup, setopenconfirmPopup] = useState(false);
  const [valid, setValid] = useState(false);
  const [series, setSeries] = useState();


  // --- redux --- //
  const groupList = useSelector(getGroupList);
  
  const sgCategory = useSelector(getNavDetails);

  // --- setDefault_status --- //
  const defaultStatus = shiftStatus.find(
    (option: any) => option.label === "active"
  );

  const navDetails = useSelector(getNavDetails);


  // --- setDefault_ss_start --- //
  const currentDate = dayjs(defaultShiftDate);
  const setCurrentDate: any = { ...currentDate };

  // --- form_defaultValues--- //
  const defaultValues = {
    // type:null,
    ss_title: null,
    ss_description: null,
    ss_start: setCurrentDate.$d,
    ss_end: "",
    ss_start_time: "",
    ss_end_time: "",
    ss_repeat: false,
    ss_whole_series: true,
    ss_repeat_details: {
      // sr_separation_count: 1,
      sr_unit: "",
      sr_day_of_week: "",
      sr_day_of_month: null,
    },
    ss_capacity: "",
    ss_sg_id: "",
    ss_repeat_end: "",
    ss_status: defaultStatus,
    members: null,
  };

  // --- useform --- //
  const {
    handleSubmit,
    control,
    getValues,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<any>({
    mode: "all",
    defaultValues: defaultValues,
    resolver: yupResolver(shiftCreat),
  });

  // --- form_getValues --//
  const getFormValues = getValues();
  // console.log(getFormValues , 'FORMCAL')


  // --- dropdown_getoptlabel && handleStatusFilter ---//
  const handleStatusFilter = () => void 0;
  const handleStatusLabel = (data: any) => data?.label;

  const handleShiftFilter = () => void 0;
  const handleShiftLabel = (data: any) => data?.sg_name;

  const handleDateFilter = () => void 0;
  const handleDateLabel = (data: any) => data?.label;

  // --- get_shiftEdit_id ---//
  const editShiftId = editShiftValues?.val.ss_id;

  // --- useform_watch --- //
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setShowDate(value?.ss_repeat);
      setShowDateField(value?.ss_repeat_details.sr_unit?.value);
      const data = setDate(value.ss_start, 1);
      const updateDate: any = { ...data };
      // setSeries(value?.ss_whole_series);
      setEndRepeat(updateDate?.$d);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  // console.log(errors, 'errors');

  useEffect(() => {
    setSeries(getFormValues?.ss_whole_series);
  }, [getFormValues, editShiftId])



  // --- form_onSubmit --- //
  const onSubmit = (Formvalue: any) => {
    const setStartTime = getUTCTime(
      Formvalue.ss_start,
      Formvalue.ss_start_time
    );
    const setEndTime = getUTCTime(
      Formvalue.ss_start,
      Formvalue.ss_end_time ? Formvalue.ss_end_time : endingTime
    );

    // const setRepeatEnd = getUTCTime(
    //   Formvalue.ss_repeat_end,
    //   Formvalue.ss_repeat_end ? Formvalue.ss_repeat_end : null
    // )

    Formvalue.ss_sg_id = Formvalue.ss_title?.sg_id.toString();
    Formvalue.ss_title = Formvalue.ss_title?.sg_name;
    Formvalue.ss_status = Formvalue?.ss_status?.id;
    Formvalue.ss_repeat_details.sr_day_of_week = week;
    Formvalue.ss_end = setEndTime;
    Formvalue.ss_start = setStartTime;
    Formvalue.ss_repeat_details.sr_unit = Formvalue.ss_repeat_details.sr_unit?.label;
    Formvalue.ss_repeat_end = getDateLocaleString(
      Formvalue.ss_repeat_end ? Formvalue.ss_repeat_end : endRepeat,
      "year"
    );
    // Formvalue.ss_repeat_end = new Date(
    //   Formvalue.ss_repeat_end ? Formvalue.ss_repeat_end : endRepeat
    // ).toISOString();
    // Formvalue.ss_repeat_end = setRepeatEnd;
    Formvalue.ss_repeat_details.sr_day_of_month =
      Formvalue.ss_repeat_details.sr_unit === "MONTH"
        ? getDateLocaleString(Formvalue.ss_start, "setdate")
        : null;
    delete Formvalue.ss_start_time;
    delete Formvalue.ss_end_time;
    const payload = {
      default: {
        ...Formvalue,
        // type: Formvalue.ss_repeat_details.sr_unit
      },
    };

    saveShift({ editShiftId, payload });
  };



  // --- shift_save_mutation ---//
  const { mutate: saveShift, isLoading: saveLoading } = useMutation({
    mutationFn: shiftService.saveShift,
    onSuccess(data: any) {
      close(false);
      SyToast(data.data.message, "top-right", "short", "success");
    },
  });

  // --- shift_delete_mutation ---//
  const { mutate: deletShift, isLoading: deleteLoading } = useMutation({
    mutationFn: shiftService.deleteShift,
    onSuccess(data: any) {
      close(false);
    },
  });

  // --- get-shift_mutation ---//
  const { mutate: getShift, isLoading: isLoading } = useMutation({
    mutationFn: shiftService.getShift,
    onSuccess(data: any) {
      setFormValues(data.data.default);
      setMembers(data?.data?.default?.members);
    },
  });

  const { mutate: validateMember } = useMutation({
    mutationFn: shiftService.validateMember,
    onSuccess(data: any) {
      if (data) {
        setValid(true);
      }
      // SyToast(data.data.message, "top-right", "short", "success");
    },
    onError(error: any) {
      setValid(false);

      SyToast(error.response.data.message, "top-right", "short", "error");
      const errorId = error.response.data.userId;
      const updatedMember = members.filter((c: any) => c.id !== errorId);
      setMembers(updatedMember);



      // setMembers([]);
    }
  });





  //validate_members
  // const {mutate:validateMember } = useMutation({
  //   mutationFn : shiftService.validateMember,
  //   onSuccess(data:any){
  //     console.log(data , 'ffffff');

  //   }
  // });

  // useEffect(()=>{
  //   validateMember("1");
  // },[])

  useEffect(() => {
    dispatch(displayOverlayLoading(isLoading ? 1 : 0));
  }, [isLoading]);

  // --- shift_delete_functions ---//
  const shiftDelete = () => {
    deletShift(editShiftId);
  };
  const deleteShiftPopup = (val: any) => {
    setDeletePopup(true);
  };

  // --- useEffect --- //
  useEffect(() => {
    // setSeries(getFormValues?.ss_whole_series)
    if (editShiftId) {
      getShift(editShiftId);
    }
  }, [editShiftId]);

  useEffect(() => {
    if (endingTime) {
      setValue("ss_end_time", endingTime);
    }
  }, [endingTime]);

  useEffect(() => {
    if (editShiftId) {
      setValue("members", members);
    } else {
      setValue("members", members);
    }
    setSeries(getFormValues?.ss_whole_series)

  }, [members]);

  useEffect(() => {
    setValue("ss_title", groupList?.filter((data: any) => navDetails.clickedData.selected?.sg_id === data?.sg_id)[0])
  }, []);

  // --- form_setValues ---//
  const setFormValues = (val: any) => {

    const settitle = groupList.find(
      (option: any) => option.sg_id === val.ss_sg_id
    );

    const setUnit = dateRepeat.find(
      (option: any) => option.label === val.sr_repeat_details?.sr_unit
    );
    const startDate = dayjs(val.ss_start);
    const setStartDate: any = { ...startDate };

    const endDate = dayjs(val.ss_end);
    const setEndDate: any = { ...endDate };

    const repeatEnd = dayjs(val.ss_repeat_end);
    const setrepeatEnd: any = { ...repeatEnd };

    const setStatus = shiftStatus.find(
      (option: any) => option.id == val.ss_status
    );
    // const setSeparationCount = val.sr_repeat_details?.sr_separation_count;

    val.ss_repeat &&
      setEditWeek(val?.sr_repeat_details?.sr_detail?.sr_day_of_week);

    setValue("ss_title", settitle);
    // setValue("ss_title", settitle);
    // setValue(
    //   "ss_title",
    //   getDataFromOptions(val.sg_category, groupList, "sg_id")
    // );
    setValue("ss_status", setStatus);
    setValue("ss_start", setStartDate.$d);
    setValue('ss_end', setEndDate.$d);
    setValue("ss_start_time", startDate);
    setValue("ss_end_time", endDate);
    setValue("ss_capacity", val.ss_capacity);
    setValue("ss_repeat_end", setrepeatEnd.$d);
    setValue("ss_repeat", val.ss_repeat);
    setValue("ss_repeat_details.sr_unit", setUnit);
    // setValue("ss_repeat_details.sr_separation_count", setSeparationCount);
    setValue("ss_description", val.ss_description);
  };

  // --- members_select --- //
  const onSelectValue = async (value: any) => {

    const data = Array.isArray(value) ? value : [value];

    setMembers((prevFileURLs: any) => {
      return [...prevFileURLs, ...data];
    });

    const lastIndex = data.length - 1;
    const lastValue = data[lastIndex];

    // console.log(getFormValues, 'vllllll');
    let savedMembers = [];
    if (editShiftId) {
      savedMembers = getSavedMember(getFormValues?.members);
    }

    // const wholeSeries = showDate ? getFormValues.ss_whole_series : false;

    const payload = {
      default: {

        // type: shedulerChange === 1 ? "Week" : "Month",
        type:'week',
        // ss_start: getFormValues?.ss_start,
        ss_capacity: getFormValues?.ss_capacity,
        ss_description: getFormValues?.ss_description,
        // ss_end: getFormValues?.ss_end_time,
        ss_end: (() => {
          let startDate = new Date(getFormValues?.ss_start);
          let endDate = new Date(getFormValues?.ss_end_time);
          endDate.setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
          return endDate?.toISOString();
        })(),
        ss_start: (() => {
          const setStartTime = getUTCTime(
            getFormValues?.ss_start,
            getFormValues?.ss_start_time
          );
          return setStartTime;
        })(),

        ss_repeat: getFormValues?.ss_repeat,
        ss_repeat_details: {
          ...getFormValues?.ss_repeat_details,
          sr_unit: getFormValues?.ss_repeat_details?.sr_unit?.label,
        },
        ss_sg_id: navDetails?.clickedData?.selected?.sg_id || editShiftValues?.val?.ss_sg_id,
        ss_repeat_end: getFormValues.ss_repeat_end || endRepeat,
        // members: [...savedMembers, ...data],
        members: [...savedMembers, ...data],
        wholeSeries: showDate ? getFormValues.ss_whole_series : false,
        // category_id : navDetails?.clickedData?.cgy_id


        // userId: editShiftId ? value?.id : lastValue?.id,
        // userType: editShiftId ? value?.type_id : lastValue?.type_id,
      },
    };
    validateMember(payload);
  }


  // const onSelectValue = async (value: any) => {
  //   console.log(value, 'valzzz');

  //   // const data = [value];
  //   const data = Array.isArray(value) ? value : [value];


  //   setMembers((prevFileURLs: any) => {
  //     return [...data, ...prevFileURLs];
  //   });

  //   const lastIndex = value.length - 1;
  //   const lastValue = value[lastIndex];

  //   console.log(getFormValues, 'vllllll');
  //   let savedMembers = [];
  //   if(editShiftId){

  //   // getFormValues.members = getSavedMembers(getFormValues.members);
  //   savedMembers = getSavedMember(getFormValues.members);

  //   }

  //   const payload = {
  //     default: {
  //       type: shedulerChange === 1 ? "Week" : "Month",
  //       ss_start: getFormValues?.ss_start_time,
  //       ss_capacity: getFormValues?.ss_capacity,
  //       ss_description: getFormValues?.ss_description,
  //       ss_end: getFormValues?.ss_end_time,
  //       ss_repeat: getFormValues?.ss_repeat,
  //       ss_repeat_details: {
  //         ...getFormValues?.ss_repeat_details,
  //         sr_unit: getFormValues?.ss_repeat_details?.sr_unit?.label, // Ensure correct assignment
  //       },
  //       ss_sg_id: navDetails?.clickedData?.selected?.sg_id,
  //       // ss_repeat_end: getFormValues.ss_repeat_end,
  //       ss_repeat_end:  getFormValues.ss_repeat_end || endRepeat,
  //       // members: value || [], 
  //       members: [...savedMembers, ...value]
  //     },
  //   };

  //   validateMember(payload);

  // }

  //   const payload = {
  //     // ...getFormValues,
  //     // groupId: navDetails?.clickedData?.selected?.sg_id,
  //     default: {
  //       type: shedulerChange === 1 ? "Week" : "Month",
  //       // userId: editShiftId ? value?.id : lastValue?.id,
  //       // userType: editShiftId ? value?.type_id : lastValue?.type_id,
  //       ss_start: getFormValues?.ss_start_time,
  //       ss_capacity: getFormValues?.ss_capacity,
  //       ss_description: getFormValues?.ss_description,
  //       ss_end: getFormValues?.ss_end_time,
  //       ss_repeat: getFormValues?.ss_repeat,
  //       ss_repeat_details: getFormValues?.ss_repeat_details,
  //       ss_sg_id: navDetails?.clickedData?.selected?.sg_id,
  //       ss_repeat_end: getFormValues?.ss_repeat_end,
  //       members: value
  //     }
  //   }
  //   payload.default.ss_repeat_details.sr_unit = getFormValues?.ss_repeat_details.sr_unit.label,

  //     validateMember(payload);
  // };




  // --- members_delete --- //
  const employeeDelete = () => {
    const updatedMember = members.filter(
      (obj: any) => obj.id !== deleteConfirmData
    );
    setMembers(updatedMember);
  };

  const deleteConfirmPopup = (val: any) => {
    setDeleteConfirmData(val);
    setopenconfirmPopup(true);
  };

  // --- asyn_select_payload --- //
  const payload = {
    group_id: getFormValues.ss_title?.sg_id.toString(),
    date: getDateLocaleString(setCurrentDate.$d, "year"),
  };

  // --- form_controller_render --- //
  const renderTitle = ({ field: { onChange, value } }: any) => {
    return (
      <Dropdown
        options={groupList}
        placeholder={"shift_group"}
        controlledVal={value}
        handleFilter={handleShiftFilter}
        handleChange={onChange}
        getoptlabel={handleShiftLabel}
        disabled={getFormValues?.ss_whole_series ? false : true}
      />
    );
  };
  const renderStartDate = ({ field: { onChange, value, onBlur } }: any) => {
    // console.log(value , '---wholeseries---')

    return (
      <DatePicker
        {...onBlur}
        Placeholder={"start_date"}
        selectedDate={onChange}
        date={value ? value : null}
        disabled={getFormValues?.ss_whole_series ? false : true}
        minDate={true}
        ss_start={getFormValues.ss_start}
      />
    );
  };

  const renderRepeatDetails = ({ field: { onChange, value } }: any) => {
    return (
      <Dropdown
        options={dateRepeat}
        placeholder={"repeat_details"}
        value={value}
        handleFilter={handleDateFilter}
        handleChange={onChange}
        getoptlabel={handleDateLabel}
        disabled={getFormValues?.ss_whole_series ? false : true}
      />
    );
  };
  const renderSeparationCount = ({ field: { onChange, value } }: any) => {
    return (
      <InputForm
        placeholder={"separation_count"}
        type={"number"}
        value={value}
        onInputChange={onChange}
      />
    );
  };

  const renderEndDate = ({ field: { onChange, value, onBlur } }: any) => {
    return (
      <DatePicker
        {...onBlur}
        Placeholder={"end_date"}
        selectedDate={onChange}
        date={endRepeat}
        disabled={getFormValues?.ss_whole_series ? false : true}
        minDate={true}
        ss_start={getFormValues.ss_start}
        ss_end={getFormValues.ss_end || endRepeat}
      />
    );
  };

  const renderShiftStatus = ({ field: { onChange, value } }: any) => {
    return (
      <Dropdown
        options={shiftStatus}
        placeholder={"shift_status"}
        controlledVal={value}
        handleFilter={handleStatusFilter}
        handleChange={onChange}
        getoptlabel={handleStatusLabel}
        clearIcon={true}
        disabled={getFormValues?.ss_whole_series ? false : true}
      />
    );
  };



  // const renderCapacity = ({ field: { onChange, value } }: any) => {
  //   return (
  //     <InputForm
  //       placeholder={"capacity"}
  //       type={"number"}
  //       value={value}
  //       onInputChange={onChange}
  //       error={errors.ss_capacity && errors.ss_capacity.message}
  //     />
  //   );
  // };

  const renderDescription = ({ field: { onChange, value } }: any) => {
    return (
      <SyTextEditor
        value={value}
        onChange={onChange}
        register={register("ss_description")}
        name={"ss_description"}
        placeholder={"description"}
      />
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {editShiftId ? (
        <div className="row">
          <div className={`${Stylesheet.title} col-6 `}>
            <div className={`${errors.ss_title ? "" : "pb-3"} `}>
              <Controller
                name="ss_title"
                control={control}
                render={renderTitle}
              />
              {errors.ss_title && <Errormessage message={"field_required"} />}
            </div>

            <div className="row">
              <div className={`${Stylesheet.timerange} col-5  me-2`}>
                <Controller
                  name={"ss_start"}
                  control={control}
                  render={renderStartDate}
                />
                {errors.ss_start && <Errormessage message={"field_required"} />}
              </div>
              <div className="mt-3">
                <SyTimeRange
                  control={control}
                  startValue={getFormValues.ss_start_time}
                  endValue={getFormValues.ss_end_time}
                  size={"lg"}
                  startName={"ss_start_time"}
                  endName={"ss_end_time"}
                  range={"01:00"}
                  yuperror={errors}
                  defaultEndTime={setEndingTime}
                />
              </div>
            </div>

            <div
              className={`${errors.ss_start || errors.ss_start_time || errors.ss_end_time
                ? ""
                : "mt-3"
                } row  `}
            >
              <div className="col-12 ">
                {/* <Controller
                  name={"ss_capacity"}
                  control={control}
                  render={renderCapacity}
                /> */}
                {getFormValues.ss_start_time && (
                  <div className="col-lg-12" >
                    <Controller
                      name="ss_capacity"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputForm
                          placeholder="capacity"
                          type="number"
                          value={value}
                          onInputChange={onChange}
                          error={errors.ss_capacity && errors.ss_capacity.message}
                        />
                      )}
                    />
                  </div>
                )}
              </div>

              <div className="col-12 mt-2">
                <SyAsynSelect
                  name={"members"}
                  placeholder={"select_employees"}
                  control={control}
                  optionValue={members}
                  multiple={false}
                  defaultVal={true}
                  onSelect={onSelectValue}
                  blurOnSelect={true}
                  SERVICE_API_FN={shiftService.getUserList}
                  payload={payload}
                  onSuccessAsyncSearch={getAsyncWildData}
                  valid={valid}
                />
                {errors?.members && (
                  <Errormessage message={errors?.members?.message} />
                )}
              </div>
            </div>

            <div
              className={`${Stylesheet.avathar} ${Stylesheet.sy_vertical_scroll} mt-3`}
            >
              {members?.length > 0 ? (
                members?.map((data: any) => (
                  <div className=" pb-1 d-flex justify-content-between">
                    <div className="col-5">
                      <Avathar
                        type="single"
                        profile={data}
                        size={"lg"}
                        showName={true}
                      />
                    </div>
                    <div className="col-2">
                      <SyChip label={data.Lable} color={"#c7eabb"} size="sm" />
                    </div>
                    {data.actual_time > 0 &&
                      <div className="col-2">
                        <SyChip
                          label={`${data?.alloted_time}/${data?.actual_time ? data?.actual_time : "0"}h`}
                          color={"#c7eabb"}
                          size="sm"
                        />
                      </div>
                    }

                    {/* <div className="col-1">
                      <SyIcons
                        name={"info"}
                        className={`cursor-pointer pointer bg-white ml-5 opacity-75`}
                        value={"add"}
                        iconType="icons"
                      />
                    </div> */}
                    <div className="col-1">
                      <SyIcons
                        name={"close"}
                        className={` cursor-pointer pointer bg-white ml-5 opacity-75`}
                        value={data.id}
                        iconType="icons"
                        onClick={deleteConfirmPopup}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className={Stylesheet.info_content}>{"no_data_found"}</div>
              )}

              <SyconfirmPopup
                onConfirmClick={employeeDelete}
                open={openconfirmPopup}
                close={setopenconfirmPopup}
              />

              <SyconfirmPopup
                onConfirmClick={shiftDelete}
                open={deletePopup}
                close={setDeletePopup}
              />
            </div>

            {showDate &&
              <div className="d-flex mt-4">
                <Controller
                  name="ss_whole_series"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <SyToggleButton
                        toggleClick={onChange}
                        value={value}
                      />
                    );
                  }}
                />
                <p>change_for_whole_series</p>
              </div>
            }
          </div>

          <div className={`${Stylesheet.title} col-6  border-start`}>
            <div className="row  mt-2">
              <div className="col-5 ">
                <Controller
                  name="ss_status"
                  control={control}
                  render={renderShiftStatus}
                />
              </div>
            </div>
            <div className="col-2 mt-3 ms-1">
              <InputForm
                type={"checkbox"}
                placeholder={"repeat"}
                {...register("ss_repeat")}
                disable={getFormValues?.ss_whole_series ? false : true}
              />
            </div>
            {showDate && (
              <div className="row mt-3 ">
                <div className="col-5  ">
                  <Controller
                    name="ss_repeat_details.sr_unit"
                    control={control}
                    render={renderRepeatDetails}
                  />
                </div>

                {/* <div className={` col-7 `}>
                  <Controller
                    name={"ss_repeat_details.sr_separation_count"}
                    control={control}
                    render={renderSeparationCount}
                  />
                </div> */}
              </div>
            )}
            {showDateField == "2" && showDate && (
              <div className="mb-3 mt-3">
                <WeekPicker value={editWeek} getData={setweek} />
              </div>
            )}
            {/* {showDate && (
              <div className={`col-5  mb-2 mt-3`}>
                <Controller
                  name={"ss_repeat_end"}
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => {
                    return (
                      <DatePicker
                        {...onBlur}
                        Placeholder={"end_dateBudyyyyy"}
                        selectedDate={onChange}
                        date={value ? value : endRepeat}
                      
                      />
                    );
                  }}
                />
              </div>
            )} */}
            {showDate && (
              <div className={`${Stylesheet.endDate} col-5  mb-2 mt-3`}>
                <Controller
                  name={"ss_repeat_end"}
                  control={control}
                  render={renderEndDate}
                />
              </div>
            )}
            <div className="">
              <Controller
                name={"ss_description"}
                control={control}
                render={renderDescription}
              />
            </div>

            <div
              className={`${showDate ? Stylesheet.saveCancelBtn : Stylesheet.saveEditDeleteBtn
                }`}
            >
              <Sybutton
                type={"button"}
                className={`${(editShiftValues.disable || shift_admin == false) ? "d-none" : ""
                  } me-3 primarybutton`}
                size={"md"}
                value={editShiftId}
                onBtnClick={deleteShiftPopup}
                label={"delete"}
              />
              <Sybutton
                type={"button"}
                className={"Secondarybutton me-3"}
                size={"md"}
                label={"cancel"}
                value={false}
                onBtnClick={close}
              />
              <Sybutton
                type={"submit"}
                className={`${(editShiftValues.disable || shift_admin == false) ? "d-none" : ""
                  } ms-2 primarybutton`}
                size={"md"}
                Loadings={saveLoading}
                label={"save"}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className=" ">
          <div className={`${errors.ss_title ? "" : "mb-3"} mb-3`}>
            <Controller
              name="ss_title"
              control={control}
              render={renderTitle}
            />
            {errors.ss_title && <Errormessage message={"field_required"} />}
          </div>
          
          <div
            className={`${errors.ss_start_time || errors.ss_end_time ? "" : "mb-3"
              } d-flex `}
          >
            <div className="col-5 me-2 ">
              <Controller
                name={"ss_start"}
                control={control}
                render={renderStartDate}
              />
              <div>
                {errors.ss_start && <Errormessage message={"field_required"} />}
              </div>
            </div>
           
          
            <SyTimeRange
              control={control}
              startValue={getFormValues.ss_start_time}
              endValue={getFormValues.ss_end_time}
              startName={"ss_start_time"}
              endName={"ss_end_time"}
              range={"01:00"}
              defaultEndTime={setEndingTime}
              yuperror={errors}
              date={getFormValues.ss_start}
            />

          </div>

          <div className="row  mb-2">
            <div className={` col-10 me-2`}>
              {/* <Controller
                name={"ss_capacity"}
                control={control}
                render={renderCapacity}
              /> */}

              {getFormValues.ss_start_time && getFormValues.ss_end_time && (
                <div className="col-5 me-2 mt-3">
                  <Controller
                    name="ss_capacity"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputForm
                        placeholder="capacity"
                        type="number"
                        value={value}
                        onInputChange={onChange}
                        error={errors.ss_capacity && errors.ss_capacity.message}
                      />
                    )}
                  />
                </div>
              )}

            </div>

            <div className="col-2 mt-2 ms-1 mb-2">
              <InputForm
                type={"checkbox"}
                placeholder={"repeat"}
                {...register("ss_repeat")}
              />
            </div>
          </div>

          <SyCollapse statement={showDate}>
            <div className="row mt-2 mb-3">
              <div className="col-5 mt-0 ">
                <Controller
                  name="ss_repeat_details.sr_unit"
                  control={control}
                  render={renderRepeatDetails}
                />
              </div>

              {/* <div className={` col-7 `}>
                <Controller
                  name={"ss_repeat_details.sr_separation_count"}
                  control={control}
                  render={renderSeparationCount}
                />
              </div> */}
            </div>
          </SyCollapse>

          <SyCollapse statement={showDateField == "2" && showDate}>
            <div className="mb-2 ">
              <WeekPicker getData={setweek} />
            </div>
          </SyCollapse>

          {showDate && (
            <div className={`${Stylesheet.endDate} mb-3`}>
              <Controller
                name={"ss_repeat_end"}
                control={control}
                render={renderEndDate}
              />
            </div>
          )}

          <div className={`${errors.ss_start || errors.ss_start_time || errors.ss_end_time
            ? ""
            : "mt-3"
            } row  `}>
            <div className="col-5">
              <Controller
                name="ss_status"
                control={control}
                render={renderShiftStatus}
              />
            </div>
            {getFormValues.ss_title?.sg_id && getFormValues.ss_capacity && (
              // <div className="col-7 ">
              //   <SyAsynSelect
              //     name={"members"}
              //     placeholder={"invite_employee"}
              //     control={control}
              //     optionValue={null}
              //     multiple={true}
              //     defaultVal={true}
              //     onSelect={onSelectValue}
              //     limitItems={1}
              //     SERVICE_API_FN={shiftService.getUserList}
              //     payload={payload}
              //     onSuccessAsyncSearch={getAsyncWildData}
              //     valid={valid}
              //   />
              //   {errors.members && (
              //     <Errormessage message={errors.members.message} />
              //   )}
              // </div>
              <>
                <div className="col-7">
                  <SyAsynSelect
                    name={"members"}
                    placeholder={"select_employees"}
                    control={control}
                    optionValue={members}
                    multiple={false}
                    defaultVal={true}
                    onSelect={onSelectValue}
                    blurOnSelect={true}
                    SERVICE_API_FN={shiftService.getUserList}
                    payload={payload}
                    onSuccessAsyncSearch={getAsyncWildData}
                  />
                  {errors?.members && (
                    <Errormessage message={errors?.members?.message} />
                  )}
                </div>
                <div
                  className={`${Stylesheet.avathar} ${Stylesheet.sy_vertical_scroll} mt-3`}
                >
                  {members?.length > 0 ? (
                    members?.map((data: any) => (
                      <div className=" pb-1 d-flex justify-content-between">
                        <div className="col-5">
                          <Avathar
                            type="single"
                            profile={data}
                            size={"lg"}
                            showName={true}
                          />
                        </div>
                        <div className="col-2">
                          <SyChip label={data.Lable} color={"#c7eabb"} size="sm" />
                        </div>
                        {data.actual_time > 0 &&
                          <div className="col-2">
                            <SyChip
                              label={`${data?.alloted_time}/${data?.actual_time ? data?.actual_time : "0"}h`}
                              color={"#c7eabb"}
                              size="sm"
                            />
                          </div>
                        }

                        <div className="col-1">
                          <SyIcons
                            name={"info"}
                            className={`cursor-pointer pointer bg-white ml-5 opacity-75`}
                            value={"add"}
                            iconType="icons"
                          />
                        </div>
                        <div className="col-1">
                          <SyIcons
                            name={"close"}
                            className={` cursor-pointer pointer bg-white ml-5 opacity-75`}
                            value={data.id}
                            iconType="icons"
                            onClick={deleteConfirmPopup}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={Stylesheet.info_content}>{"no_data_found"}</div>
                  )}

                  <SyconfirmPopup
                    onConfirmClick={employeeDelete}
                    open={openconfirmPopup}
                    close={setopenconfirmPopup}
                  />

                  <SyconfirmPopup
                    onConfirmClick={shiftDelete}
                    open={deletePopup}
                    close={setDeletePopup}
                  />
                </div>
              </>

            )}
          </div>

          <div className="">
            <Controller
              name={"ss_description"}
              control={control}
              render={renderDescription}
            />
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Sybutton
              type={"button"}
              className={"Secondarybutton me-2"}
              size={"md"}
              label={"cancel"}
              value={false}
              onBtnClick={close}
            />
            <Sybutton
              type={"submit"}
              Loadings={saveLoading}
              className={"primarybutton"}
              size={"md"}
              label={"save"}
            />
          </div>
        </div>
      )}
    </form>
  );
};

export default ShiftPopup;
