import React, { useEffect, useState } from "react";
import { shiftOverview } from "./config";
import SyChip from "../../../../../../shared/components/sy-chip";

type Props = {};

const WeekPicker = ({ getData, value }: any) => {
  const [weekActiveIds, setWeekActiveIds] = useState<any>([]);

  const onWeakClick = (val: any) => {
    const data = weekActiveIds.includes(val);

    if (data) {
      const index = weekActiveIds.indexOf(val);
      if (index !== -1) {
        const weekActive = [...weekActiveIds];
        weekActive.splice(index, 1);
        setWeekActiveIds(weekActive);
      }
    } else {
      const weekActive = [...weekActiveIds, val];

      setWeekActiveIds(weekActive);
    }
  };

  useEffect(() => {
    getData(weekActiveIds);
  }, [weekActiveIds]);

  useEffect(() => {
    setWeekActiveIds(value ? value : "");
  }, []);

  return (
    <div className=" d-flex pb-2  ">
      {shiftOverview.map((data: any, index: any) => (
        <div className="pe-1">
          <SyChip
            label={data.day}
            color={`${weekActiveIds.includes(data.id) ? "	#6495ed" : "#dddddd"}`}
            type="rounded_circle"
            size="lg"
            value={data.id}
            onClickChip={onWeakClick}
          />
        </div>
      ))}
    </div>
  );
};

export default WeekPicker;
