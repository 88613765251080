//react-hook
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//styles
import Stylesheet from "./style.module.scss";

//redux-slice
import { getOverviewList } from "../../../../../../feature/shift-slice";

//components
import SyCircle from "../../../../../../../../shared/sy-circle-progressbar";
import { shiftOverview } from "./config";

//type
import {Props} from './overview-modal'

const Overview = ({ sg_id, type }: Props) => {
  // const shiftOverview = useSelector(getOverviewList)

  const { t } = useTranslation();


  return (
    <>
      {/* <div className="justify-content-center align-items-center border rounded"> */}
      {/* <div className="">
        <h5 className="ps-4">overview</h5>
      </div> */}
      <div
        className={` ${
          type === 1 && sg_id
            ? Stylesheet.outline_group
            : type === 1 !&& sg_id
            ? Stylesheet.outline
            : type === 2 && !sg_id
            ? Stylesheet.month
            : type === 2 && sg_id
            ? Stylesheet.month_view
            :  Stylesheet.outline
        } sy_vertical_scroll  d-flex justify-content-center align-items-center`}
      >
        no_data_found
        {/* <div className="p-2">
          <h6 className="m-0 ps-2 primary_color sticky-top bg-white py-1">{t("overview")}</h6>
          {shiftOverview.map((data: any, index: number) => (
            <div key={index} className={`d-flex justify-content-between p-2 mb-2`}>
              <div className="col-8 pe-0 ">
                <p className={`mb-0 opacity-50 ellipsis`}>{data.heading}</p>
                <p className="mb-0">
                  <span>
                    <b>{data.time}</b>
                  </span>
                  <span> {t("from")} {data.total}</span>
                </p>
              </div>
              <SyCircle value={data.value} key={index} />
            </div>
          ))}
        </div> */}
      </div>
      {/* </div> */}
    </>
  );
};

export default Overview;
