import { useEffect, useState } from "react";
import SyChip from "../../../../../../shared/components/sy-chip";
import SyColorpicker from "../../../../../../shared/components/forms/sy-color-picker";
import { selectableColors } from "./config";
import SyIcons from "../../../../../../shared/components/sy-icons";

type Props = {
  value: string;
  onChange: (data: any) => void;
};

const ShiftColorSelector = (props: Props) => {
  const { onChange, value } = props;

  const [selected, setSelected] = useState(0);
  const [color, setColor] = useState<string>("#1A9DD9");

  useEffect(() => {
    const colorExists = getexistingcolor(value);
    colorExists ? setSelected(colorExists.id) : setColor(value);
  }, [value]);

  const handleClick = (data: any) => {
    onChange(data.color);
    setSelected(data.id);
  };

  const handleColorChange = (data: any) => {
    onChange(data.target.value);
    setSelected(0);
  };

  const getexistingcolor = (color: any) =>
    selectableColors.find((data: any) => data.color === color);

  return (
    <>
      <div className="d-flex sy_horizontal_scroll">
        {selectableColors.map((data: any, index: number) => {
          return (
            <div className="pe-1 d-flex align-items-center" key={index}>
              <SyChip
                labelIcon={
                  data.id === selected ? (
                    <SyIcons
                      iconType="icons"
                      iconStyle="outlined"
                      name={"check"}
                    />
                  ) : (
                    "  "
                  )
                }
                color={data.color}
                type="rounded_circle"
                size="lg"
                value={data}
                onClickChip={handleClick}
              />
            </div>
          );
        })}
        <div className="d-flex align-content-center">
          <SyColorpicker
            value={color}
            type="round"
            onColorChange={handleColorChange}
            selected={!selectableColors.find((data: any) => data.id === selected)} editIcon={true}          />
        </div>
      </div>
    </>
  );
};

export default ShiftColorSelector;
