import Stylesheet from "./style.module.scss";
import SyChip from "../../../../../../shared/components/sy-chip";
import Avathar from "../avathar/avathar";
import { getDateLocaleString } from "../../../../../../shared/utils/dayjs";
import tinycolor from "tinycolor2";
import { useTranslation } from "react-i18next";

type props = {
  shiftData?: any;
  groupId?: any;
  disable?: any;
};

const ShiftCard = (props: props) => {
  const { shiftData, groupId, disable } = props;
  // console.log(shiftData , 'shiftData----->');
  
  const { t } = useTranslation();
  const color = tinycolor(
    `${groupId ? shiftData?.ss_color : shiftData?.sg_color}`
  )
    .lighten(20)
    .toString();

  const chipStyle = {
    backgroundColor: color,
  };

  const startTime = getDateLocaleString(shiftData?.ss_start, "time");
  const endTime = getDateLocaleString(shiftData?.ss_end, "time");

  const Header = () => {
    return (
      <>
        <div className={`${Stylesheet.card1}  `}>
          <div className="pb-2 ">
            <SyChip
              label={`${startTime}-${endTime}`}
              color={`${groupId ? shiftData?.ss_color : shiftData?.sg_color}`}
              size="sm"
              className={"ms-2 p-1 px-2"}
            />
            <p className="mb-0  ms-4 xm-font">{shiftData.ss_title}</p>
            <p className="mb-0 ms-4 xm-font">
              <b>
                <span>{shiftData?.members.length}</span>
                <span> {t("from")} </span>
                <span> {shiftData?.ss_capacity}</span>
              </b>
            </p>
          </div>
        </div>
      </>
    );
  };
  const Profile = (data: any) => {
    return (
      <div
        className={`${Stylesheet.avathar} ${Stylesheet.sy_vertical_scroll} ms-2 `}
      >
        {shiftData.members.map((data: any) => (
          <Avathar type="single" profile={data} size={"md"} showName={true} />
        ))}
      </div>
    );
  };
  const List = () => {
    return (
      <>
        <div
          style={chipStyle}
          className={`${
            disable ? Stylesheet.card_disable : Stylesheet.card
          }  p-1 `}
        >
          <Header />

          {groupId && <Profile />}
        </div>
      </>
    );
  };

  return (
    <>
      <List />
    </>
  );
};
export default ShiftCard;
