//react hooks
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//service
import shiftService from "../../service/shift-service";
//helper
import {
  getDataFromOptions,
  getGroupLabel,
  getStatusLabel,
} from "./helper";
//redux-slice
import { getNavDetails, setGroupList } from "../../../../feature/shift-slice";
import { displayOverlayLoading } from "../../../../feature/common-slice";
//forms
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//components
import { InputForm } from "../../../../../../shared/components/forms";
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import SyTextEditor from "../../../../../../shared/components/forms/sy-rich-text-edit";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import { defaultValues, status } from "./shift-group-config";
import { groupProps, formValues } from "./shift-group-interface";
import ShiftColorSelector from "../../components/shift-color-selector";
import { groupSchema } from "../../components/schema-validation/schema-validation";
import { SyToast } from "../../../../../../shared/utils/sy-toast";


const ShiftGroupManage = (props: groupProps) => {
  const { groupOptions, shiftId, saveVariables, closePopup } = props;
  
  // const [typeId, setTypeId] = useState(1);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navDetails = useSelector(getNavDetails);

  const { mutate: saveMutate, isLoading: saveLoading } = useMutation({
    mutationFn: shiftService.shiftGroupSave,
    onSuccess(data, variables) {
      closePopup();
      saveVariables(variables)
      SyToast(data.data.message, "top-right", "short", "success");
    },
  });

  const { mutate: getShiftDetails, isLoading: getGroupLoading } = useMutation({
    mutationFn: shiftService.getShiftGroupData,
    onSuccess(data) {  
      setFormValues(data.data);
    },
  });

  useEffect(() => {
    // **overlay loading** 
    dispatch(displayOverlayLoading(getGroupLoading ? 1 : 0));
  }, [getGroupLoading]);

  // const { data: memberlist, mutate: getMemberList } = useMutation({
  //   mutationFn: shiftService.getwildCard,
  // });

  useEffect(() => {
    shiftId
      ? getShiftDetails(shiftId)
      : 
      setValue(
        "sg_category",  
        groupOptions.filter(
          (data: any) => navDetails.clickedData.cgy_id === data.cgy_id
        )[0]
      );
  }, []);

  const {
    handleSubmit,
    control,
    setValue,
    register,
    setFocus,
    formState,
  } = useForm<formValues>({
    mode: "all",
    defaultValues: defaultValues,
    resolver: yupResolver<any>(groupSchema),
  });

  // const { isDirty } = getFieldState("sg_type", formState);

  // useEffect(() => {
  //   setTypeId(getValues("sg_type").id);
  // }, [isDirty]);

  const setFormValues = (data: any) => {

    // ***********************************************************
    // **this function is to set values on form at edit scenario** 
    // ***********************************************************

    setValue("sg_desc", data.sg_desc);
    setValue("sg_name", data.sg_name);
    setValue(
      "sg_category",
      getDataFromOptions(data.sg_category, groupOptions, "cgy_id")
    );
    setValue("sg_status", getDataFromOptions(data.sg_status, status, "value"));
    setValue("sg_color", data.sg_color);
    // setValue("sg_req_hrs", parseInt(data.sg_req_hrs));
    // setValue("sg_type", getDataFromOptions(data.sg_type, shift_type, "id"));
    // setTypeId(data.sg_type);
  };

  useEffect(() => {
    setFocus("sg_name")  // **set focus on first field**
  }, [])


  // const handleOnfocus = () => {
  //   // searchValue("");
  //   getMemberList({ searchValue: "", payload: wildcard });
  // };

  // const handleFilter = (searchtext: any) => {
  //   // searchValue(searchtext);
  //   getMemberList({ searchValue: searchtext, payload: wildcard });
  // };

  const onSubmit = (saveData: any) => {
    saveData.sg_category = saveData.sg_category?.cgy_id;
    saveData.sg_status = saveData.sg_status?.value;
    saveData.sg_type = 1;

    const payload = {
      default: {
        ...saveData,
        sg_exc: 0,
      },
    };
    saveMutate({ id: shiftId ? shiftId : "", payload });
  };

  const onCancelClick = () => closePopup();

  const handleKeyDown = (e: any) => {
    //*******************************************************************//
    //*****this function is to prevent form submit on pressing enter*****//
    //*******************************************************************//
    e.key === "Enter" && e.preventDefault()
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className={`${formState.errors?.sg_name?.message ? "lh-1" : "pb-3"} mb-3`}>
          {/* {typeId === 1 ? ( */}
          <Controller
            control={control}
            name="sg_name"
            render={({ field: { onChange, value } }) => {
              return (
                <InputForm
                  placeholder={t("group_name")}
                  type={"text"}
                  {...register("sg_name")}
                  onInputChange={onChange}
                  value={value}
                  error={formState.errors.sg_name?.message}
                />
              );
            }}
          />
          {/* ) : (
            <Controller
              control={control}
              name="sg_ref"
              render={({ field: { onChange, value } }) => {
                return (
                  <Dropdown
                    options={memberlist?.data ? memberlist.data : []}
                    controlledVal={value || null}
                    placeholder={"client"}
                    handleChange={onChange}
                    getoptlabel={getMemberLabel}
                    enableSearchBox={true}
                    onFocus={handleOnfocus}
                    handleFilter={handleFilter}
                  />
                );
              }}
            />
          )} */}
        </div>
        <div className={`${formState.errors?.sg_category?.message ? "lh-1" : "pb-3"} mb-3`}>
          <Controller
            control={control}
            name="sg_category"
            render={({ field: { onChange, value } }) => {
              return (
                <Dropdown
                  options={groupOptions}
                  controlledVal={value}
                  placeholder={t("category_name")}
                  handleChange={onChange}
                  getoptlabel={getGroupLabel}
                  errorMessage={formState.errors.sg_category?.message}
                />
              );
            }}
          />
        </div>
        {/* <div className="pt-2">
          <Controller
            control={control}
            name="sg_type"
            render={({ field: { onChange, value } }) => {
              return (
                <Dropdown
                  options={shift_type}
                  controlledVal={value}
                  placeholder={"type"}
                  handleChange={onChange}
                  getoptlabel={getStatusLabel}
                />
              );
            }}
          />
        </div> */}
        <div className={`${formState.errors?.sg_status?.message ? "lh-1" : "pb-3"} col-6`}>
          <Controller
            control={control}
            name="sg_status"
            render={({ field: { onChange, value } }) => {
              return (
                <Dropdown
                  options={status}
                  controlledVal={value}
                  placeholder={t("status")}
                  handleChange={onChange}
                  getoptlabel={getStatusLabel}
                  // clearable={false}
                  errorMessage={formState.errors?.sg_status?.message}
                />
              );
            }}
          />
        </div>

        {/* <div className="pt-2">
          <Controller
            control={control}
            name="sg_req_hrs"
            render={({ field: { onChange, value } }) => {
              return (
                <InputForm
                  placeholder="required_hours_per_week"
                  type={"number"}
                  onInputChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </div> */}
        <div>
          <Controller
            name="sg_color"
            control={control}
            render={({ field: { onChange, value } }) => {
              return <ShiftColorSelector value={value} onChange={onChange} />;
            }}
          />
        </div>
        <div className="pt-2">
          <Controller
            control={control}
            name="sg_desc"
            render={({ field: { onChange, value } }) => {
              return (
                <SyTextEditor
                  value={value}
                  onChange={onChange}
                  placeholder={t("description")}
                />
              );
            }}
          />
        </div>
        <div className="d-flex justify-content-end mt-5 mb-2">
          <Sybutton
            className={`Secondarybutton me-2`}
            label={t("close")}
            type={"reset"}
            onBtnClick={onCancelClick}
            size={"sm"}
          />
          <Sybutton
            className={`primarybutton me-2`}
            label={t("save")}
            type={"submit"}
            size={"sm"}
            Loadings={saveLoading}
          />
        </div>
      </form>
    </>
  );
};

export default ShiftGroupManage;
