//react-hooks
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";


//components
import Sybutton from "../../../sy-button/sy-button";
import Stylesheet from "./style.module.scss";
import SyIcons from "../../../sy-icons";

//dayjs
import dayjs from "dayjs";
import SyChip from "../../../sy-chip";

//types
import { props } from "./header-sheduler-modal";
import { getNavDetails } from "../../../../../projects/simpler/feature/simpler-slice";

const HeaderSheduler = (props: props) => {
  const {
    headerButton,
    currentWeek,
    handlePreviousClick,
    handleNextClick,
    onButtonClick,
    shedulerChange,
    currentMonth,
    groupId,
    shiftAdd,
    shift_admin
  } = props;

  const { t } = useTranslation();

  const navDetails = useSelector(getNavDetails);

  const onIconClick = (val: any) => {
    shiftAdd(val);
  };

  const updateYearWeek = () => {
    const startOfWeek = dayjs().week(currentWeek).startOf("week");
    const weekNumbers = Array.from({ length: 7 }, (_, index) =>
      startOfWeek.add(index, "day").week()
    );

    const weekYears = Array.from({ length: 7 }, (_, index) =>
      startOfWeek.add(index, "day").format("YYYY")
    );
    const weekNumbergetOne = weekNumbers[0];

    return (
      <>
        <span>
          {weekNumbergetOne} , {weekYears[0]}
        </span>
      </>
    );
  };

  const updateYearMonth = () => {
    const startOfMonth = dayjs().month(currentMonth).startOf("month");
    const monthNumbers = Array.from({ length: 7 }, (_, index) =>
      startOfMonth.add(index, "day").format("MMMM")
    );
    const monthYears = Array.from({ length: 7 }, (_, index) =>
      startOfMonth.add(index, "day").format("YYYY")
    );
    const monthNumbergetOne = monthNumbers[0];
    return (
      <>
        <span className="">
          {t(monthNumbergetOne)} , {monthYears[0]}
        </span>
      </>
    );
  };
  
 const disable = navDetails.clickedData.selected?.sg_id ? false : true ;
 
  return (
    <>
      <div className={` w-100 p-3 ps-1 pe-3 d-flex justify-content-between flex-wrap`}>
        <div className=" d-flex justify-content-start col-xl-4 col-lg-4 col-md-3 mb-3" >
          {shift_admin && (
            <div>
              <Sybutton
                label={t("new_shift")}
                type={"button"}
                size={"sm"}
                value={"new"}
                className={`primarybutton  p-2  ms-3`}
                onBtnClick={onIconClick}
                disabled={disable}
              />
              {/* <Sybutton
             
              icontype="language"
              value={`${" SmartPlanner"}`}
              type={"button"}
              size={"sm"}
              className={"primarybutton py-1 "}
            /> */}
            </div>
          )}
        </div>
        <div
          className={`${shift_admin
              ? ""
              : "  col-3"
            } d-flex justify-content-center pt-2 col-xl-4 col-lg-4 col-md-4 mb-3 pe-1`}
        >
          <SyIcons
            name={"chevron_left"}
            className={`${Stylesheet.arrowIcon} pointer bg-white ml-5`}
            iconType="icons"
            value={shedulerChange}
            onClick={handlePreviousClick}
          />
          <p className="ellipsis">
            {shedulerChange == "1" ? t("cw-") : shedulerChange == "2" ? "" : null}
            {shedulerChange == "1"
              ? updateYearWeek()
              : shedulerChange == "2"
                ? updateYearMonth()
                : null}
          </p>

          <SyIcons
            name={"navigate_next"}
            className={`${Stylesheet.arrowIcon} pointer bg-white ml-5`}
            iconType="icons"
            value={shedulerChange}
            onClick={handleNextClick}
          />
        </div>

        <div
          className={`d-flex justify-content-center col-xl-4 col-lg-4 col-md-5 mb-3 ps-1`}
        >
          {headerButton.map((button: any, index: number) => (
            <span key={index}>
              <Sybutton
                className={`${shedulerChange == button.value
                    ? Stylesheet.active
                    : Stylesheet.inactive
                  } me-2 p-2 border`}
                label={t(button.label)}
                type={"button"}
                size={"sm"}
                value={button.value}
                onBtnClick={onButtonClick}
              />
            </span>
          ))}
        </div>
      </div>
    </>
  );
};
export default HeaderSheduler;
