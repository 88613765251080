import React from "react";
import Collapse from "@mui/material/Collapse";

type Props = {
  children?: React.ReactNode;
  statement: boolean;
};

const SyCollapse = (props: Props) => {
  const { children = "", statement = false } = props;
  return (
    <div>
      <Collapse in={statement}>{children}</Collapse>
    </div>
  );
};

export default SyCollapse;
