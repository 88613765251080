export const getStatusLabel = (options: any) => options?.label;

export const getGroupLabel = (options: any) => options?.cgy_name;

export const getMemberLabel = (options: any) => options?.name;

export const getDataFromOptions = (id: any, data: any, key: any) => {
  return data.filter((data: any) => {
    return data[key] === id;
  })[0];
};
