export const wildcard = {
  includeCommunity: false,
  filter: [{ type: "module", filter_type: "include", mod_ids: [2] }],
};

export const status = [
  {
    value: 1,
    label: "active",
  },
  {
    value: 2,
    label: "inactive",
  },
];


export const shedulerButton = [
  {
    value: 1,
    label: "week",
  },
  {
    value: 2,
    label: "month",
  },
];