//react-hooks
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";

//styles
import Stylesheet from "./style.module.scss";
import SyIcons from "../../../sy-icons";

//dayjs
import dayjs from "dayjs";
import { getDateLocaleString } from "../../../../utils/dayjs";

//components
import ShiftCard from "../../../../../projects/shift/modules/shift-module/components/shift-card";
import SyChip from "../../../sy-chip";
import SyHtmlTooltip from "../../../sy-html-tooltip";

//types
import { Props } from "./month-sheduler-modal";
import { getNavDetails } from "../../../../../projects/simpler/feature/simpler-slice";

const MonthSheduler = (props: Props) => {
  const {
    currentMonth,
    shiftAdd,
    shedulerList,
    weekList,
    shedulerChange,
    groupId,
    shiftEdit,
    shift_admin,
  } = props;

  const { t } = useTranslation();
  const [cardShow, setCardShow] = useState(false);

  const datesInMonth: any = [];
  const startOfMonth = dayjs().month(currentMonth).startOf("month");
  const daysInMonth = startOfMonth.daysInMonth();
  
  const navDetails = useSelector(getNavDetails);


  for (let i = 0; i < daysInMonth; i++) {
    const currentDate = startOfMonth.add(i, "day");
    datesInMonth.push({
      weekFormate: currentDate.format("dd"),
      day: currentDate.format("D"),
      date: currentDate.format("YYYY-MM-DD"),
      shedulerChange: shedulerChange,
    });
  }

  let timesOfDay: any = [
    { start: "00:00", end: "05:59" },
    { start: "06:00", end: "11:59" },
    { start: "12:00", end: "17:59" },
    { start: "18:00", end: "23:59" },
  ];

  const slotData: any = [[]];
  for (let day = 0; day < daysInMonth + 1; day++) {
    slotData.push([[], [], [], []]);
  }
  
  const disabled = navDetails.clickedData.selected?.sg_id ? true : false ;


  const getSlotData = (slotData: any) => {
    slotData = { ...slotData };

    shedulerList?.map((monthList:any)=>{
      return{
        ...monthList,
        ss_start : new Date(monthList.ss_start),
        ss_end: new Date(monthList.ss_end),  
      }
    }).map((data: any) => {
      if (
        !slotData[`${getDateLocaleString(data.ss_start, "setdateFormate")}`]
      ) {
        slotData[`${getDateLocaleString(data.ss_start, "setdateFormate")}`] =
          [];
      }
      const setIndex = timesOfDay.findIndex((value: any, index: any) => {
        return (
          value.start <= getDateLocaleString(data.ss_start, "time") &&
          value.end >= getDateLocaleString(data.ss_start, "time")
        );
      });

      slotData[`${getDateLocaleString(data.ss_start, "setdateFormate")}`][
        setIndex
      ]?.push(data);
      return data;
    });

    return slotData;
  };

  const onIconClick = (val: any) => {
    onCloseToolTip();
    shiftAdd(val);
  };

  const onCardClick = (val: any) => {
    onCloseToolTip();
    shiftEdit(val);
  };

  const onCardShow = (val: any) => {
    setCardShow(val);
  };

  const onCloseToolTip = () => {
    setCardShow(false);
  };

  const TableHead = (item: any) => {
    return item.item.weekFormate === "Su" || item.item.weekFormate === "Sa" ? (
      <tr className={`${Stylesheet.heading_tr_weekEnd}`}>
        <p className={`${Stylesheet.day} opacity-25 mt-4`}>{item?.item?.day}</p>
      </tr>
    ) : (
      //  <tr className={`${Stylesheet.heading_tr} pb-5 `}>
      //   <p className={`${Stylesheet.week}`}>{t(item?.item?.weekFormate)}</p>
      //   <p className={`${Stylesheet.day} opacity-50`}>{item?.item?.day}</p>
      // </tr>
      <tr className={`${Stylesheet.heading_tr} pb-5 `}>
        <p className={`${Stylesheet.week}`}>{t(item?.item?.weekFormate)}</p>
        <p className={`${Stylesheet.day} opacity-50`}>{item?.item?.day}</p>
      </tr>
    );
  };
   

  //table design v2
  const TableRowBody = ({ item, daysOfMonth }: any) => {
    return daysOfMonth.map((value: any, colIndex: any) => {
      const currentDate = getDateLocaleString(new Date(), "year");
      const disable = currentDate <= value.date && disabled ? false : true;
      //  value.weekFormate === "Su" || value.weekFormate === "Sa"
      const isWeekend =
        value.weekFormate === "Su" || value.weekFormate === "Sa";
      const weekEnd = isWeekend
        ? `${Stylesheet.icon_data_WeekEnd}`
        : `${Stylesheet.icon_data}`;
      return (
        <td key={colIndex} className={weekEnd}>
          {item.item[value.day].map((val: any) => {
            return val.length === 0 ? (
              <div className={`${Stylesheet.slot} `}>
                <span onClick={() => onIconClick(value)}>
                  {shift_admin && (
                    <SyIcons
                      name={"add_circle_outline"}
                      // className={`${
                      //   disable ? Stylesheet.disableIcon : Stylesheet.arrowIcon
                      // } `}
                      className={`${
                      
                        currentDate <= value.date && disabled
                          ? Stylesheet.arrowIcon
                          : Stylesheet.disableIcon
                      } `}
                      iconType="icons"
                    />
                  )}
                </span>
              </div>
            ) : (
              val.map((val: any) => {
                const disable = getDateLocaleString(new Date(), "utcFormate") <= getDateLocaleString(val.ss_start, "utcFormate")  ? false : true;
                return (
                  <div className={`${Stylesheet.slotChip} `}>
                    <SyHtmlTooltip
                      htmlElement={
                        <div onClick={() => onCardClick({ val, disable })}>
                          <ShiftCard
                            shiftData={val}
                            groupId={groupId}
                            disable={disable}
                          />{" "}
                        </div>
                      }
                      children={
                        <div
                          className={`${Stylesheet.slotSyChip} `}
                          onClick={() => onCardShow(val.ss_id)}
                          onMouseOver={() => onCardShow(val.ss_id)}
                        >
                          <SyChip
                            type="rounded_square"
                            className={`${
                              disable ? Stylesheet.disableChip : ""
                            }`}
                            color={`${groupId ? val?.ss_color : val?.sg_color}`}
                          />
                        </div>
                      }
                      open={cardShow === val.ss_id}
                      onClickAway={onCloseToolTip}
                      // disableTouch={true}
                    />
                  </div>
                );
              })
            );
          })}
        </td>
      );
    });
  };

  const TableColBody = (item: any) => {
    return (
      <tr className={`${Stylesheet.header_tr}`}>
        <TableRowBody item={item} daysOfMonth={datesInMonth} />
      </tr>
    );
  };

  useEffect(() => {
    weekList(datesInMonth);
  }, [currentMonth]);

  return (
    <>
      <table className={`${Stylesheet.table} w-100 `}>
        <thead className={`${Stylesheet.thead} `}>
          {datesInMonth.map((item: any, index: any) => {
            return <TableHead item={item} />;
          })}
        </thead>
        <tbody>
          <TableColBody item={getSlotData(slotData)} />
        </tbody>
      </table>
    </>
  );
};

export default MonthSheduler;
