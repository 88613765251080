//react-hooks
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//styles
import Stylesheet from "./style.module.scss";

//dayjs
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";

//redux-slice
import {
  getShiftAccessList,
  getShiftList,
} from "../../../projects/shift/feature/shift-slice/scheduler-slice";

//components
import HeaderSheduler from "./components/header-sheduler";
import WeeklySheduler from "./components/week-sheduler";
import MonthSheduler from "./components/month-sheduler";

//type
import { props } from "./sy-sheduler-modal";

const SySheduler = (props: props) => {
  const [shedulerChange, setShedulerChange] = useState("1");
  const {
    headerButton,
    shiftAdd,
    groupId,
    weekList,
    shiftEdit,
    onChangeType,
    shift_admin,
    sgLoading,
    accEmp
  } = props;
  dayjs.locale("en");
  dayjs.extend(weekOfYear);
  
  
  const shiftList = useSelector(getShiftList);
  const shiftAccessList = useSelector(getShiftAccessList);  
  
  const [currentMonth, setCurrentMonth] = useState(dayjs().month());
  const [currentWeek, setCurrentWeek] = useState(dayjs().week()); 
  const [shiftData, setShiftData] = useState([]);

  const handlePreviousClick = (val: any) => {
    if (val == "1") {
      setShiftData([]);
      setCurrentWeek((currentWeek) => currentWeek - 1);
    } else if (val == "2") {
      setShiftData([]);
      setCurrentMonth((currentMonth) => currentMonth - 1);
    }
  };

  const handleNextClick = (val: any) => {
    if (val == "1") {
      setCurrentWeek((currentWeek) => currentWeek + 1);
      setShiftData([]);
    } else if (val == "2") {
      setShiftData([]);
      setCurrentMonth((currentMonth) => currentMonth + 1);
    }
  };

  const onButtonClick = (val: any) => {
    setShiftData([]);
    setShedulerChange(val);
    onChangeType(val);
  };

  // if (shift_admin) {
  //   useEffect(() => {
  //     setShiftData(shiftList);
  //   }, [shiftList]);
  // } else if (!shift_admin) {
  //   useEffect(() => {
  //     setShiftData(shiftAccessList);
  //   }, [shiftAccessList]);
  // }
  useEffect(() => {
    setShiftData(shift_admin && !accEmp?.id ? shiftList : shiftAccessList);
  }, [shift_admin, shiftList, shiftAccessList]);
  

  return (
    <>
      <div className="d-flex bg-white rounded-top">
        <HeaderSheduler
          headerButton={headerButton}
          currentWeek={currentWeek}
          currentMonth={currentMonth}
          handlePreviousClick={handlePreviousClick}
          handleNextClick={handleNextClick}
          onButtonClick={onButtonClick}
          shedulerChange={shedulerChange}
          groupId={groupId}
          shiftAdd={shiftAdd}
          shift_admin={shift_admin}
        />
      </div>
      <div
        className={`${
          Stylesheet.sy_vertical_scroll
        }  row d-flex mx-auto rounded-bottom border border-0 ${
          shedulerChange == "1"
            ? Stylesheet.weekSheduler
            : Stylesheet.monthSheduler
        }    `}
      >
        <div className="sy_horizontal_scroll ">
          {shedulerChange == "1" ? (
            <WeeklySheduler
              weekList={weekList}
              shedulerChange={shedulerChange}
              currentWeek={currentWeek}
              shiftAdd={shiftAdd}
              shiftEdit={shiftEdit}
              groupId={groupId}
              shedulerList={shiftData}
              shift_admin={shift_admin}
            />
          ) : (
            <MonthSheduler
              currentMonth={currentMonth}
              shiftAdd={shiftAdd}
              shedulerList={shiftData}
              weekList={weekList}
              shedulerChange={shedulerChange}
              groupId={groupId}
              shiftEdit={shiftEdit}
              shift_admin={shift_admin}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SySheduler;
