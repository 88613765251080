import React, { useEffect, useRef, useState } from "react";
import SyTimePicker from "../sy-time-picker";
import Stylesheet from "./style.module.scss";
import {
  currentTime,
  getDateLocaleString,
  getTimeDifference,
  timeValidation,
} from "../../../utils/dayjs";
import SyIcons from "../../sy-icons";
import { Controller } from "react-hook-form";

import Errormessage from "../errormessage";
import dayjs from "dayjs";

type Props = {};

const SyTimeRange = ({
  startName,
  endName,
  range,
  control,
  startValue,
  date,
  endValue,
  defaultEndTime,
  size,
  yuperror,
  setStartTime,
  setEndTime,
  className,
}: any) => {
  const startTime = useRef<HTMLInputElement | null>(null);
  const endTime = useRef<HTMLInputElement | null>(null);
  const [startMin, setStartMin] = useState<any>();
  const [endMin, setEndMin] = useState<any>();
  const [end, setEnd] = useState<any>();
  const [onclick, setOncick] = useState(false);
  const [lableChange, setLableChange] = useState(false);
  const [endlableChange, setEndLableChange] = useState(false);

  const onClickStartTime = () => {
    if (startTime.current) {
      startTime.current.click();
    }
  };

  const onClickEndTime = () => {
    if (endTime.current) {
      endTime.current.click();
    }
  };

  const onchangeStartTime = (val: any) => {
    const startTime = getDateLocaleString(val?.$d, "time");

    if (startTime && onclick) {
      const updateTime = getTimeDifference(val?.$d, range);
      setEnd(updateTime);

      defaultEndTime(updateTime);
    }
  };

  const handleOKClick = () => {
    setOncick(true);
  };
  const handleOnClick = () => {
    setLableChange(true);
  };
  const handleOnClickEnd = (value: any) => {
    setEndLableChange(true);
  };
  const handleOnBlur = () => {
    setLableChange(false);
    setEndLableChange(false);
  };

  const onchangeEndTime = (val: any) => {

    const endTime = getDateLocaleString(val?.$d, "time");
    const statTimeVal = getDateLocaleString(startValue?.$d, "time");
    const timeValidate = timeValidation(statTimeVal);
    setEndMin(timeValidate);
    setEnd(endTime);

  };

  // useEffect(() => {
  //   if (setStartTime) {
  //     const startTime = getDateLocaleString(setStartTime?.$d, "time");
  //     setStart(startTime);

  //     const endTime = getDateLocaleString(setEndTime?.$d, "time");
  //     setEnd(endTime);
  //   }
  // }, []);

  useEffect(() => {
    if (startValue?.$d != "Invalid Date") {
      onchangeStartTime(startValue);
    }
  }, [startValue]);
  useEffect(() => {
    onchangeStartTime(startValue);
  }, [onclick]);

  useEffect(() => {
    if (endValue) {
      onchangeEndTime(endValue);
      setEnd(null);
    }
  }, []);
  

  useEffect(() => {
    const formDate = getDateLocaleString(date, "date");
    const currDate = new Date().toLocaleDateString();
    const validDate = getDateLocaleString(currDate, "date");
    if (validDate === formDate) {
      const currTime = currentTime();
      const timeValidate = timeValidation(currTime);
      setStartMin(timeValidate);
      setEndMin(timeValidate);
    } else {
      setStartMin(null);
      setEndMin(null);
    }
    
  }, [date]);

  return (
    <div className="d-flex flex-wrap">
      <div className="w-100 ">
      <div
        className={`${
          size === "lg"
            ? Stylesheet.input_lg
            : size === "sm"
            ? Stylesheet.input_sm
            : Stylesheet.input
        } d-flex w-100 ${className}`}
      >
        <div className="col-6 d-flex justify-content-between ">
          <div className="col-9">
            <Controller
              name={startName}
              control={control}
              render={({ field: { onChange, value, onBlur } }) => {
                
                
                return (
                  <SyTimePicker
                    reference={startTime}
                    onChangeValue={onChange}
                    handleOKClick={handleOKClick}
                    type={"text"}
                    fieldSize={`${
                      lableChange || startValue ? "sm_border" : size ==='sm'? "sm_holder": "sm_lable"
                    }`}
                    lable={"start_time"}
                    value ={startValue || null}
                    handleOnClick={handleOnClick}
                    handleOnBlur={handleOnBlur}
                    minValue={startMin}
                  />
                );
              }}
            />
          </div>
          <div className="mt-2 me-0 ">
            <SyIcons
              name={"schedule"}
              className={"  opacity-80 me-2 cursor-pointer pointer "}
              onClick={onClickStartTime}
            />
          </div>
        </div>
        <div className={`col-6 d-flex  justify-content-between  `}>
          <div className="col-9">
            <Controller
              name={endName}
              control={control}
              render={({ field: { onChange, value, onBlur } }) => {
              
                return (
                  <SyTimePicker
                    reference={endTime}
                    onChangeValue={onChange}
                    fieldSize={`${
                      endlableChange || endValue || end
                        ? "sm_border": size ==='sm'? "sm_holder"
                        : "sm_lable"
                    }`}
                    type={"text"}
                    lable={"end_time"}
                    value={endValue ? endValue : end}
                    handleOnClick={handleOnClickEnd}
                    handleOnBlur={handleOnBlur}
                    minValue={endMin}
                  />
                );
              }}
            />
          </div>

          <div className="mt-2">
            <SyIcons
              name={"schedule "}
              className={"opacity-80  me-2 cursor-pointer pointer "}
              onClick={onClickEndTime}
            />
          </div>
        </div>
      </div>
      </div>
      <div className="d-flex w-100 justify-content-end">
        {yuperror?.ss_start_time && (
          <Errormessage
            message={yuperror?.ss_start_time.message}
            clasName={`me-5 pe-4`}
          />
        )}
        {yuperror?.ss_end_time && !yuperror?.ss_start_time && (
          <Errormessage message={yuperror.ss_end_time.message} />
        )}
      </div>

      <div className="ms-2 d-none ">
        <Controller
          name={startName}
          control={control}
          render={({ field: { onChange, value, onBlur } }) => {
            return (
              <SyTimePicker
                reference={startTime}
                onChangeValue={onChange}
                handleOKClick={handleOKClick}
                type={"picker"}
                value={value || null}
                minValue={startMin}
              />
            );
          }}
        />
        <Controller
          name={endName}
          control={control}
          render={({ field: { onChange, value, onBlur } }) => {
            return (
              <SyTimePicker
                reference={endTime}
                onChangeValue={onChange}
                type={"picker"}
                minValue={endMin}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default SyTimeRange;
