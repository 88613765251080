import {createSlice} from "@reduxjs/toolkit";

interface RootState{
    param:any;
}
const initialState:RootState={
    param:{},
}

export const paramSlice=createSlice({
    name:'param',
    initialState,
    reducers:{
        setParamVal:(state,{payload}) => {
            state.param = payload;
        },
    }
})

export const {setParamVal} = paramSlice.actions;

export const getParamVal =(state:any) => state.paramReducer.param;

export default paramSlice.reducer;