//react-hooks
import {useState,useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

//styles
import Stylesheet from "./style.module.scss";

//redux-slice
import { getNavDetails } from "../../../../../../feature/shift-slice/nav-slice";
import { getGroupList } from "../../../../../../feature/shift-slice/scheduler-slice";

//components
import Sybutton from "../../../../../../../../shared/components/sy-button/sy-button";
import SyIcons from "../../../../../../../../shared/components/sy-icons";
import SyLoadingIcon from "../../../../../../../../shared/components/sy-loading-icon";

//types
import {Props} from "./dashboard-nav-modal"

const Dashboardnav = (props: Props) => {
  
  const {
    onClickBtn,
    data,
    onGroupPopupClick,
    onCategoryPopupClick,
    onShiftgroupClick,
    sgLoading,
    cgyLoading,
    assigned
  } = props;
     
  const selectedItem = useSelector(getNavDetails);
  const groupList = useSelector(getGroupList)
  const {t} = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const onClickGroupPopup = () => {
    onGroupPopupClick();
  };

  const onClickCgyBtn = (val: any) => {
    onClickBtn(val);
  };

  const onShiftGroupListClick = (val: any) => {    
    onShiftgroupClick(val);
  };

  const edit = () => {
    onCategoryPopupClick();
  };

  return (
    <div className="bg-white rounded">
      <div className="d-flex p-2">
        <p className={Stylesheet.chooseCat}>choose_category:</p>
      </div>
      <div className="d-flex align-items-center">
        <div className={"d-flex  sy_horizontal_scroll p-2"}>
          {data?.map((f: any, i: any) => (
            <div key={i} className="unor me-2">
              <Sybutton
                key={f.cgy_id}
                className={`${
                  selectedItem?.clickedData.cgy_id === f.cgy_id
                    ? Stylesheet.navButton
                    : Stylesheet.navButtonHover
                } pointer `}
                onBtnClick={() => onClickCgyBtn(f)}
                label={f.cgy_name}
                type={"button"}
                size={"sm"}
              />
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {cgyLoading ? (
            <div className="pb-1">
              <SyLoadingIcon />
            </div>
          ) : (
            <SyIcons
              name={"add_circle_outline"}
              className={`pointer ms-2 pb-1 ${Stylesheet.addIcon}`}
              iconType="icons"
              onClick={edit}
            />
          )}
        </div>
      </div>

      {
        selectedItem.master.length !== 0 &&
        <div className="d-flex p-2">
        <p className={Stylesheet.chooseCat}>choose_group:</p>
      </div>}
      <div className={"d-flex align-items-center rounded"}>
        <div className="d-flex justify-content-start align-items-center sy_horizontal_scroll p-1">
          {groupList?.map((f: any, i: any) => (
            <div key={i} className="unor me-2">
              <Sybutton
                label={f.sg_name}
                type="button"
                size="sm"
                className={`${
                  selectedItem?.clickedData.selected?.sg_id === f.sg_id
                    ? Stylesheet.navButton
                    : Stylesheet.navButtonHover
                } pointer `}
                onBtnClick={() => onShiftGroupListClick(f)}
              />
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end me-auto">
          {sgLoading ? (
            <div className="p-2 mb-2 mt-1">
              <SyLoadingIcon />
            </div>
          ) : selectedItem.master.length !== 0 ? (
            <SyIcons
              name={"add_circle_outline"}
              className={`${Stylesheet.addIcon} pointer ps-2 p-2 mb-2 mt-1`}
              iconType="icons"
              onClick={onClickGroupPopup}
            />
          ) : (
            void 0
          )}
        </div>
        {/* { assigned?.shiftNotAssignedCount > 0 &&
        <div className="d-flex me-2 ms-2">
          <Sybutton
            type={"button"}
            className={`${Stylesheet.flagbutton} d-flex align-items-center text-white`}
            size={"sm"}
            icontype="outlined_flag"
            value={width > 555 ? `${assigned?.shiftNotAssignedCount} ${t("shifts_not_assigned")}` : ""}
            onBtnClick={void null}
            iconclass={Stylesheet.flagIcon}
          />
        </div>
        } */}
      </div>
    </div>
  );
};

export default Dashboardnav;
export const Previous_page = () => window.history.back();
