
import http from "../../../../axios";
import { storeData, storeDataJson } from "../../../../shared/utils"
import {  changeThemee } from "../../../../shared/utils/theming";

class RouteService {

  getTranslation() {
    return http().get(`/public/translations/get/en`);
  }

  getUserDetails() {
    return http().post(`/public/auth/sso/data`, {});
  }

  setUserDetails(data: any) {
    if (data.jwt_token) {
      storeData("jwt_token", data.jwt_token);
      storeDataJson("company_details", data.company_details);
      storeDataJson("user_details", data.user_details);
      storeData("opus_admin", data.opus_admin);
      storeData("proplan_inspect", data.proplan_inspect);
      storeData("proplan_admin", data.proplan_admin);
      storeData("hrprofile_admin", data.hrprofile_admin);
      storeData("hrprofile_inspect", data.hrprofile_inspect);
      storeDataJson("privilege_details", data.privilege_details);
      storeDataJson("module_details", data.module_details);
      changeThemee();
    }
  }

}
export default new RouteService();