import { useEffect, useReducer } from "react";
import "../../../src/App.scss"; 
import AppRoutes from "./routes";
import { BrowserRouter } from "react-router-dom";
import { AuthContext } from "./context";
import { authReducer, initialState } from "../shift/modules/auth-module/reducer";
import { getJsonData ,getData} from "../../shared/utils";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import http, { setAuthToken } from "../../axios";
import { changeThemee } from "../../shared/utils/theming";
import config from "../../environments/env-config";
import { ToastContainer } from "react-toastify";
import { getCookie } from "../../shared/utils/cookie";
import { useSelector, useDispatch } from "react-redux";
import { getIsLoading, setUserDetailReducer,setCompanyDetailReducer,setTokenReducer ,getThemecolor,setThemecolorReducer,setUserNameReducer} from "./feature/common-slice";
import Syoverlayloader from "../../shared/components/sy-overlayloader";


const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => {
      if (error.request) {
        // handle network error here
      }
    },
  }),
});



function App() {
  const dispatchRed = useDispatch();
  const isOverlayLoading = useSelector(getIsLoading);
  const [state, dispatch] = useReducer(authReducer, initialState);
  const contextData = { ...state, dispatch };

  const setDetailToRedcer =async () => {
    dispatchRed(setUserDetailReducer(await getJsonData("user_details")))
    dispatchRed(setCompanyDetailReducer(await getJsonData("company_details")))
    dispatchRed(setTokenReducer(await getData("jwt_token")))
    dispatchRed(setThemecolorReducer(await getData("cy_themecolor")))
    dispatchRed(setUserNameReducer(await getData("username")))
  
  }

  const Themecolor = useSelector(getThemecolor)
  
  useEffect(() => {
    async function prepare() {
      const authToken = getCookie(config.auth_token);
      dispatchRed(setUserDetailReducer(await getJsonData("user_details")));
      dispatchRed(setTokenReducer(authToken));    
      setDetailToRedcer()
      // const authTheme = await getJsonData("cy_themecolor");
      changeThemee('#1A9DD9');
      // changeTheme('#1A9DD9');
      dispatch({
        type: "IS_READY",
        data: { authToken: authToken, isAppReady: true },
      });
      authToken && setAuthToken(http("main_url") ,authToken);
    }
    prepare();
  }, []);

  if (!state.isAppReady) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider value={contextData}>
        <BrowserRouter>
          <ToastContainer />
          <Syoverlayloader loading={isOverlayLoading} />
          <AppRoutes />
        </BrowserRouter>
      </AuthContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
